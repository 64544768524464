import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupSecondLevel from "./popupSecondLevel";
import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';

const ListSecondLevel = () => {
  // To traslate the words
  const { t } = useTranslation();

  // Spaces
  const tab = '\u00A0';

  // To get the information of the menu
  const [dataMenu, setDataMenu] = useState([]);

  // To get the information of the menus
  const [listMenus, setListMenus] = useState([]);

  // To get the information of the menus
  const [listMenuFirstLevel, setListMenuFirstLevel] = useState([]);

  // To get the list of status
  const [listStatus, setListStatus] = useState([]);

  //id del menu primer nivel
  const [IdMenu, setIdMenu] = useState("");

  // To determinate if the event is create or edit:  edit:true and create:false
  const [isEdit, setIsEdit] = useState(false);

  const [controlModal, setControlModal] = useState(false);

  // To get the status of Provider
  const [statusMenu, setStatusMenu] = useState('');
  
  //var to order default
  const [orderMenu, setOrderMenu] = useState('');

  // Use effect is launch one time when the page load
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getMenuSecondLevel`)
      .then((response) => {
        var aux = response.data.menuSecondLevel
        setListMenus(aux);

        //calculate de order menu
        setOrderMenu(Math.max(...aux.map(o => parseInt(o.orderSubMenuSecondLevel))) + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //get menu primer nivel
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getMenuFirstLevel`)
      .then((response) => {
        setListMenuFirstLevel(response.data.menuFirstLevel);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get the list of status only load once time
  useEffect(() => {
    // We pass like parameter 1 because 1 has the general status
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/processState/${1}`)
      .then((response) => {
        setListStatus(response.data.listStatus);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  
  const cellRenderAction = (data) => {
    return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editMenu(data)} /></div>;
  }

  const createMenu = (e) => {
    setDataMenu(
      {
        nameSubMenuSecondLevel: "",
        typeSubMenuSecondLevel: "",
        descriptionSubMenuSecondLevel: "",
        statusSubMenuSecondLevel: "",
        iconSubMenuSecondLevel: "",
        IdMenu: "",
        orderSubMenuSecondLevel: orderMenu,
      }
    );
    setIsEdit(false);
    setControlModal(!controlModal);
  };

  const editMenu = (e) => {
    setIsEdit(true);
    setDataMenu(e.data);
    setIdMenu(e.data.IdMenu);
    setControlModal(!controlModal);
    setStatusMenu(e.data.statusSubMenuSecondLevel);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Menu" title={t("titleListSecondLevel")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12">
            <Card>
              <CardBody>
                <Row >
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <div id="data-grid-demo" className="table-primary">

                        {/* Popup */}
                        <PopupSecondLevel
                          controlModal={controlModal} setControlModal={setControlModal}
                          dataMenu={dataMenu}
                          isEdit={isEdit}
                          listStatus={listStatus}
                          setListMenus={setListMenus}
                          statusMenu={statusMenu} 
                          setStatusMenu={setStatusMenu}
                          listMenuFirstLevel={listMenuFirstLevel}
                          IdMenu={IdMenu} setIdMenu={setIdMenu}
                        />

                        <div className="btn-showcase ">
                          <Button className="btn-pill" color="primary" onClick={createMenu}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                        </div>

                        <DataGrid
                          dataSource={listMenus}
                          keyExpr="IdSubMenuSecondLevel"
                          showBorders={true}
                          rowAlternationEnabled={true}
                          columnAutoWidth={true}
                          t={t}
                        >
                          <HeaderFilter visible={true} allowSearch={true} />
                          <Export enabled={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                          <Scrolling
                            useNative={false}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar="onHover" />
                          <Paging defaultPageSize={5} />
                          <Pager
                            showPageSizeSelector={true}
                          />

                          <Editing
                            mode="popup"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={false}>
                          </Editing>

                          <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                          <Column dataField="IdMenu" caption={t("menuFirstLevel")} >
                            <Lookup dataSource={listMenuFirstLevel} valueExpr="IdMenu" displayExpr="nameOptionMenu" />
                          </Column>
                          <Column dataField="nameSubMenuSecondLevel" caption={t('nameMenu')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="descriptionSubMenuSecondLevel" caption={t('description')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="iconSubMenuSecondLevel" caption={t('iconName')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="statusSubMenuSecondLevel" caption={t("selectStatus")} >
                            <Lookup dataSource={listStatus} valueExpr="id" displayExpr="name" />
                          </Column>
                        </DataGrid>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default ListSecondLevel;
