import { Home, Airplay, Box, FolderPlus, Command, Cloud, FileText, Server, BarChart, Users, Archive, Layers, ShoppingBag, ShoppingCart, List, Mail, MessageCircle, GitPullRequest, Monitor, Heart, Clock, Zap, CheckSquare, Calendar, Image, Film, HelpCircle, Radio, Map, Edit, Sunrise, Package, Slack, Globe } from 'react-feather'

export const IconsMenu = [
    { name: 'Home', variable: Home },
    { name: 'Airplay', variable: Airplay },
    { name: 'Box', variable: Box },
    { name: 'FolderPlus', variable: FolderPlus },
    { name: 'Command', variable: Command },
    { name: 'Cloud', variable: Cloud },
    { name: 'FileText', variable: FileText },
    { name: 'Server', variable: Server },
    { name: 'BarChart', variable: BarChart },
    { name: 'Users', variable: Users },
    { name: 'Archive', variable: Archive },
    { name: 'Layers', variable: Layers },
    { name: 'ShoppingBag', variable: ShoppingBag },
    { name: 'ShoppingCart', variable: ShoppingCart },
    { name: 'List', variable: List },
    { name: 'Mail', variable: Mail },
    { name: 'MessageCircle', variable: MessageCircle },
    { name: 'GitPullRequest', variable: GitPullRequest },
    { name: 'Monitor', variable: Monitor },
    { name: 'Heart', variable: Heart },
    { name: 'Clock', variable: Clock },
    { name: 'Zap', variable: Zap },
    { name: 'CheckSquare', variable: CheckSquare },
    { name: 'Calendar', variable: Calendar },
    { name: 'Image', variable: Image },
    { name: 'Film', variable: Film },
    { name: 'HelpCircle', variable: HelpCircle },
    { name: 'Radio', variable: Radio },
    { name: 'Map', variable: Map },
    { name: 'Edit', variable: Edit },
    { name: 'Sunrise', variable: Sunrise },
    { name: 'Package', variable: Package },
    { name: 'Slack', variable: Slack },
    { name: 'Globe', variable: Globe }, 
    
];



