import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import './home.css';
import HomePage from './components/pages/HomePage';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import  SignIn  from "../auth/signin";

const Home = () => {
  // To traslate the words
  const { t } = useTranslation();

  return (
    <div>
      <Navbar></Navbar>
      <HomePage></HomePage>
    </div>
  );

};




export default Home;
