import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useForm, Controller } from 'react-hook-form'
import { Alert, Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { SelectBox } from 'devextreme-react/select-box';
import Productpage from "../application/ecommerce-app/productpage";
import { useParams } from "react-router-dom";
import { SiWhatsapp, SiFacebook, SiGooglemaps } from "react-icons/si";
import ReCAPTCHA from "react-google-recaptcha";



const CustomerForm = (props) => {
  const captcha = useRef(null);
  const [captchaValido, setCaptchaValido] = useState(null);

  // To traslate the words
  const { t } = useTranslation();
  // Spaces
  const tab = '\u00A0';

  // Class allow validate
  const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

  const [validateClass, setValidateClass] = useState(false);

  //for errors setup
  const [error, setError] = useState({});

  // Loading
  const [loading, setLoading] = useState(false);

  // To get the information of the sub customer
  const [dataCustomer, setDataCustomer] = useState([]);

  // To get the information of the customers
  const [listCustomers, setListCustomers] = useState([]);

  // To get the information of the social media
  const [listSocialMedia, setListSocialMedia] = useState([]);
  const [idSocialMedia, setIdSocialMedia] = useState('');

  // Determinates if the through  selected is seller in marketplace
  const [isSellerMarketplace, setIsSellerMarketplace] = useState(false);


  // Show and hide  delivery fields
  const [showFieldDelivery, setShowFieldDelivery] = useState(false);

  // Charge delivery
  const [chargeDelivery, setChargeDelivery] = useState(25);
  const [price, setPrice] = useState(0);
  const [brand, setBrand] = useState('');
  // To get the comment of Customer
  const [comment, setComment] = useState('');

  // Note request
  const [noteRequest, setNoteRequest] = useState([]);

  // Total payment
  const [productName, setProductName] = useState(0);

  // Set note advise
  const [noteAdvise, setNoteAdvise] = useState(t("alertNoteVisit"));

  const [singleProductImg, setSingleProductImg] = useState({});
  // Take id
  let { id } = useParams();

  // Save language
  const [languageClient, setLanguageClient] = useState('en');

  //Get the language
  const browserLanguaje = navigator.language || navigator.userLanguaje;


  const onSubmit: SubmitHandler<FormValues> = data => {
    // If all validations are met we'll call register method
    if (captcha.current.getValue()) {
      createRequest(data);
    } else {
      toast.warning(t('acceptCaptcha'));
    }
  }

  // Use effect is launch one time when the page load
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/socialMediaCustomers`)
      .then((response) => {
        setListSocialMedia(response.data.socialMedia);
      })
      .catch((error) => {
        console.log(error);
      });

    setDataCustomer(
      {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        marketSeller: "",
        address: "",
        comment: "",
        ourAddress: "20952 Sherman Way Canoga Park  ca. 91364"
      }
    );
  }, []);




  const handleChangeSocialMedia = (newvalue) => {

    if (newvalue.value !== null) {

      // Set the Id Social Media
      if (newvalue.value !== undefined) {

        // If the option selected is marketplace seller
        if (newvalue.value.idSocialMedia === 1 || newvalue.value.idSocialMedia === '1') {
          setIsSellerMarketplace(true);
          console.log(newvalue.value.idSocialMedia);

        } else {
          setIsSellerMarketplace(false);
        }
        setIdSocialMedia(newvalue.value.idSocialMedia);
      }

    } else {

      setIsSellerMarketplace(false);
      setIdSocialMedia('');
    }
  }


  const handleRadioButtonDelivery = (event) => {
    // If the value target is 1  the answer was yes
    if (event.target.value === '1') {
      setShowFieldDelivery(true);

      // The customer wants delivery
      setNoteAdvise(t("alertNoteDelivery"));
      setChargeDelivery(25);

    } else {
      setShowFieldDelivery(false);
      setChargeDelivery(0);

      setNoteAdvise(t("alertNoteVisit"));

    }

    setValidateClass(false);

  }


  // Function that allow save a new record
  const createRequest = (data) => {
    //validation empy fields
    if (id !== '' && id !== null && id !== undefined &&
      idSocialMedia !== '' && idSocialMedia !== false && idSocialMedia !== null) {
      setLoading(true);

      const infoCreate = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        address: data.address,
        phoneNumber: data.phoneNumber,
        marketSeller: data.marketSeller,
        comment: comment,
        showFieldDelivery: showFieldDelivery,
        chargeDelivery: chargeDelivery,
        idProduct: id,
        idSocialMedia: idSocialMedia,
        totalPurchase: (chargeDelivery + price),
        price: price,
        languageClient: languageClient
      };


      axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/createRequestRefrigerator`, infoCreate)
        .then((response) => {
          setValidateClass(false);
          setLoading(false);
          toast.info(t('successCreated'));

          // Load the list of brands
          emptyFields();

        })
        .catch((errors) => {
          setError(errors.response.data.messages)
          console.log(errors);
        });
    }

  };

  function emptyFields() {
    setValidateClass(false);
    reset();
    setIdSocialMedia('');
    setNoteRequest([]);
  }

  const handleBlurName = () => {
    setNoteRequest([]);

    if (showFieldDelivery === true) {
      setChargeDelivery(25);
    } else {
      setChargeDelivery(0);
    }

    // Remove sticky note
    noteRequest.map(v => {
      v.isDeleted = true;
    });
    setNoteRequest([...noteRequest,
    {
      id: 1,
      isDeleted: false,
      productName: productName,
      description: noteAdvise,
      price: price,
      chargeDelivery: chargeDelivery,
      brand: brand
    }]);
  }

  useEffect(() => {

    if (browserLanguaje.includes('es')) {
      setLanguageClient('es');
    } else {
      setLanguageClient('us');
    }
    loadProductsById(id);
  }, [id]);


  function loadProductsById(idProduct) {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getInfoProductoById/${idProduct}`)
      .then((response) => {
        setPrice(response.data[0].price);
        setBrand(response.data[0].tags[0]);
        setSingleProductImg(response.data[0]);
        setProductName(response.data[0].name);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const onChangeRecaptcha = () => {
    if (captcha.current.getValue()) {
      console.log("El usuario no es un robot");
    }
  }



  return (
    <Fragment>
      {/* <Breadcrumb title={t("formClient")} /> */}
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12" xl="10">
            <Card>
              <CardBody>
                <Row>

                  <Col sm="12" lg="12" xl="12" className="col-12">

                    <div className="product-box row" align="center">
                      <Alert color="light dark">
                        <p>{t('deliveryWarning')}</p>
                      </Alert>
                    </div>

                    <br />
                  </Col>

                  <Col sm="12" lg="6" xl="6" className="col-12">

                    <div className="product-box row" align="center">
                      <Productpage data={singleProductImg} />
                    </div>
                  </Col>
                  <Col sm="12" lg="6" xl="6">

                    <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                      <Col className="col-12" style={{ marginTop: '10px' }}>
                        <h6 className="sub-title formTitleRegisterCustomer">{t("generalInformation")}</h6>
                      </Col>
                      <Row>
                        <Col md="6 mb-2">
                          <Label>{t("firstName")}</Label>
                          <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.firstName}  {...register('firstName', { required: true })} onBlur={handleBlurName} />
                          <span>{errors.firstName && t("errorRequired")}</span>
                        </Col>
                        <Col md="6 mb-2">
                          <Label>{t("lastName")}</Label>
                          <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.lastName}  {...register('lastName', { required: true })} />
                          <span>{errors.lastName && t("errorRequired")}</span>
                        </Col>
                        <Col md="6 mb-2">
                          <Label>{t("phoneNumber")}</Label>
                          <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.phoneNumber}  {...register('phoneNumber', { required: true })} />
                          <span>{errors.phoneNumber && t("errorRequired")}</span>
                        </Col>

                        <Col md="6 mb-2">
                          <Label>{t("email")}</Label>
                          <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.email} {...register('email', {
                            required: false,
                            pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
                          })} />
                          <span>{errors.email && t("errorEmail")}</span>
                        </Col>

                        <Col md="6 mb-2">
                          <Label>{t("socialMedia")}</Label>
                          <SelectBox
                            dataSource={listSocialMedia}
                            displayExpr="nameSocialMedia"
                            value={listSocialMedia.find(v => v.idSocialMedia === idSocialMedia)}
                            searchEnabled={true}
                            className={'form-control dxSelectBorder'}
                            showClearButton={true}
                            name="selectSocialMedia"
                            onValueChanged={handleChangeSocialMedia}
                          />
                          <input type="hidden" />
                          <span>{((idSocialMedia === "" || listSocialMedia === false || idSocialMedia === null) && validateClass) && t("errorSocialMedia")}</span>
                        </Col>
                        {
                          isSellerMarketplace ? (
                            <Col md="6 mb-2">
                              <Label>{t("marketPlaceSeller")}</Label>
                              <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.marketSeller} {...register('marketSeller', { required: false })} />
                            </Col>
                          ) : ''

                        }

                        <Col md="6 mb-2">
                          <Label>{t("deliveryQuestion")}</Label>
                          <FormGroup className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml" onChange={handleRadioButtonDelivery}>
                            <div className="radio radio-primary">
                              <Input id="radioinline1" type="radio" name="radio1" value="1" />
                              <Label className="mb-0" for="radioinline1">{t("answerDelivery")}</Label>
                            </div>
                            <div className="radio radio-primary">
                              <Input id="radioinline2" type="radio" name="radio1" value="2" defaultChecked />
                              <Label className="mb-0" for="radioinline2">{t('answerVisit')}</Label>
                            </div>
                          </FormGroup>
                        </Col>

                        {
                          showFieldDelivery === true ? (
                            <Col md="12 mb-1">
                              <Label>{t("address")}</Label>
                              <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.address}  {...register('address', { required: true })} />
                              <span>{errors.address && t("errorAddress")}</span>
                            </Col>

                          ) : (
                            <>
                              <Col md="9 mb-1">
                                <Label>{t("msgOurAddress")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.ourAddress} readOnly={true} />
                              </Col>
                              <Col md="3 mb-2">
                                <span style={{ fontSize: '50px' }} >
                                  <a href='https://goo.gl/maps/ThKE6QLSyVt5k6yv7' target={"blank"}>
                                    <SiGooglemaps></SiGooglemaps>
                                  </a>
                                </span>
                              </Col>
                            </>
                          )
                        }


                        <Col md="12 mb-1">
                          <Label>{t("notes")}</Label>
                          <Input type="textarea" className="form-control btn-pill" rows="2" name="comment" defaultValue={dataCustomer.comment} onChange={(e) => setComment(e.target.value)} />
                        </Col>

                        <Col md="2 mb-2">
                        </Col>
                        <Col md="10 mb-2" sm="12" align="center" >
                          <div className="sticky-note" id="board" align="center" >
                            {noteRequest.map((data, index) => (
                              <div className={`note ui-draggable ui-draggable-handle ${data.isDeleted ? "d-none" : ""}`} key={index} align="center">
                                <div className="note_cnt" style={{ textAlign: "justify" }}>
                                  <p className="title" style={{ fontSize: '20px', textAlign: "center" }} placeholder="Enter note title" readOnly={true}><b> {t('totalPay') + ': $' + (chargeDelivery + data.price)}</b></p>
                                  <p className="cnt" placeholder="Enter note description here" readOnly={true} style={{ height: "20px" }}>
                                    {
                                      noteAdvise
                                    }
                                    <br /><br />
                                    <b>{t('productName')}:</b> {data.productName}
                                    <br />
                                    <b>{t('nameBrand')}:</b> {data.brand}
                                    <br />
                                    <b>{t('price')}:</b>${data.price}
                                    <br />
                                    {
                                      showFieldDelivery === true ? (
                                        <>< b > {t('deliveryService')}:</b> $ {chargeDelivery} <br /></>
                                      ) : ''
                                    }
                                    <b>{t('total')}:</b> ${(chargeDelivery + data.price)}

                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Col>
                        <Col md="12 mb-1" align="center">
                          <ReCAPTCHA
                            ref={captcha}
                            sitekey="6LfVctYhAAAAACEaaKqX5i_4-lg8L7n54w0IVw28"
                            onChange={onChangeRecaptcha}
                          />
                        </Col>
                      </Row>

                      <ModalFooter>
                        <Button color="secondary" onClick={emptyFields} >{t('cancel')}</Button>
                        <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : t('request')}</Button>
                      </ModalFooter>
                    </Form>

                    <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                      <span></span></div>
                    </div>
                    <br /><br />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment >
  );

};

export default CustomerForm;
