import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from "axios";
import { SelectBox } from 'devextreme-react/select-box';

export default function PopupModules(
    {
        controlModal,
        setControlModal,
        dataModule,
        isEdit,
        listStatus, setListModules,
        statusModule, setStatusModule,
        listMenuFirstLevel,
        listMenuSecondLevel,
        listMenuThirdLevel,
        IdMenu, setIdMenu,
        IdSubMenuSecondLevel, setIdSubMenuSecondLevel,
        IdSubMenuThirdLevel, setIdSubMenuThirdLevel
    }
) {

    const [loading, setLoading] = useState(false);

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    // Class allow validate
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

    const [validateClass, setValidateClass] = useState(false);

    //aux of list
    const [auxSecondLevel, setAuxSecondLevel] = useState([]);
    const [renderSecondLevel, setRenderSecondLevel] = useState(1);
    const [auxThirdLevel, setAuxThirdLevel] = useState([]);
    const [renderThirddLevel, setRenderThirddLevel] = useState(1);

    //for errors setup
    const [error, setError] = useState({});

    // User translation
    const { t } = useTranslation();

    const onSubmit: SubmitHandler<FormValues> = data => {
        // If all validations are met we'll call register method
        if (isEdit) {
            updateModule(data);
        } else {
            createModule(data);
        }
    }

    useEffect(() => {
        setValidateClass(false);
        reset({
            moduleName: dataModule.moduleName,
            moduleDescription: dataModule.moduleDescription,
        });

        //fiter arrays
        setAuxSecondLevel(listMenuSecondLevel.filter(val => val.IdMenu == IdMenu));
        //fiter arrays
        setAuxThirdLevel(listMenuThirdLevel.filter(val => val.IdSubMenuSecondLevel == IdSubMenuSecondLevel));
    }, [controlModal])

    const handleStatusModule = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setStatusModule(newvalue.value.id);
        } else {
            // Clear the information of Status menu
            setStatusModule('');
        }
    }

    const handleMenuFirstLevel = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setIdMenu(newvalue.value.IdMenu);

            // Clean object in sub-category
            setIdSubMenuSecondLevel('');
            setAuxSecondLevel([]);

            setIdSubMenuThirdLevel('');
            setAuxThirdLevel([]);

            // Get the sub categories by id category
            axios.get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getSecondLevelByFirst/${newvalue.value.IdMenu}`)
            .then((payload) => {
                // If everything is good, load the array of departments in the Typeahead or select
                setAuxSecondLevel(payload.data.menu);
            })
            .catch((error) => {
                console.log(error);
            });
        } else {
            // Clear the information of Id menu
            setIdMenu('');

            //fiter arrays
            setAuxSecondLevel([]);
            setIdSubMenuSecondLevel('');

            setIdSubMenuThirdLevel('');
            setAuxThirdLevel([]);
        }
    }

    const handleMenuSecondLevel = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setIdSubMenuSecondLevel(newvalue.value.IdSubMenuSecondLevel);

            // Clean object in sub-category
            setIdSubMenuThirdLevel('');
            setAuxThirdLevel([]);

            // Get the sub categories by id category
            axios.get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getThirdLevelBySecond/${newvalue.value.IdSubMenuSecondLevel}`)
            .then((payload) => {
                // If everything is good, load the array of departments in the Typeahead or select
                setAuxThirdLevel(payload.data.menu);
            })
            .catch((error) => {
                console.log(error);
            });
        } else {
            // Clear the information of Id menu
            setIdSubMenuSecondLevel('');

            setIdSubMenuThirdLevel('');
            setAuxThirdLevel([]);
        }
    }

    const handleMenuThirdLevel = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setIdSubMenuThirdLevel(newvalue.value.IdSubMenuThirdLevel);
        } else {
            // Clear the information of Id menu
            setIdSubMenuThirdLevel('');
        }
    }

    const changeStatusModalMenu = () => {
        setControlModal(!controlModal)
    };

    // Function that allow save a new record
    const createModule = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
            //validation empy fields
            setLoading(true);

            // Make sure that the vals are not empty
            if (IdMenu === '' || IdMenu === undefined) {
                setLoading(false);
                return false;
            }

            const infoCreate = {
                moduleName: data.moduleName,
                moduleDescription: data.moduleDescription,
                IdMenu: IdMenu,
                IdSubMenuSecondLevel: IdSubMenuSecondLevel,
                IdSubMenuThirdLevel: IdSubMenuThirdLevel,
                whoCreate: infoUserLogin.id
            };

            axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/modulesCtr`, infoCreate)
                .then((response) => {
                    setValidateClass(false);
                    setLoading(false);
                    toast.info(t('successCreated'));

                    // Load the list of brands
                    loadModules();
                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                    console.log(errors);
                });
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }
    };

    // Function that allow update the record
    const updateModule = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '' && dataModule.IdModule !== undefined) {
            //validation empy fields
            if(statusModule !== undefined && statusModule !== '') {
                setLoading(true);

                const infoUpdate = {
                    moduleName: data.moduleName,
                    moduleDescription: data.moduleDescription,
                    moduleStatus: statusModule,
                    IdMenu: IdMenu,
                    IdSubMenuSecondLevel: IdSubMenuSecondLevel,
                    IdSubMenuThirdLevel: IdSubMenuThirdLevel,
                    whodidit: infoUserLogin.id
                };

                axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/modulesCtr/${dataModule.IdModule}`, infoUpdate)
                .then((response) => {
                    setValidateClass(false);
                    setLoading(false);
                    toast.info(t('successUpdated'));
                    loadModules();
                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                });
            }
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }

    };


    //function to reload the menu
    function loadModules() {
        axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/modulesCtr`)
        .then((response) => {
            setListModules(response.data.modules);
            setControlModal(!controlModal);
        })
        .catch((error) => {
            console.log(error);
        });
    }


    return (
        <Fragment>
            <Modal
                size="lg" isOpen={controlModal} centered>
                <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={changeStatusModalMenu}>
                        {isEdit ? t("editInfo") : t("createInfo")}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="6 mb-2">
                                <Label>{t("menuFirstLevel")}</Label>
                                <SelectBox
                                    dataSource={listMenuFirstLevel}
                                    displayExpr="nameOptionMenu"
                                    value={listMenuFirstLevel.find(v => v.IdMenu === IdMenu)}
                                    searchEnabled={true}
                                    className={'form-control dxSelectBorder'}
                                    placeholder={t('menuFirstLevel')}
                                    showClearButton={true}
                                    name="menuFirstLevel"
                                    onValueChanged={handleMenuFirstLevel}
                                />
                                <input type="hidden" />
                                <span>{((IdMenu === "" || IdMenu === undefined) && validateClass) && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("menuSecondLevel")}</Label>
                                <SelectBox
                                    dataSource={auxSecondLevel}
                                    displayExpr="nameSubMenuSecondLevel"
                                    value={auxSecondLevel.find(v => v.IdSubMenuSecondLevel === IdSubMenuSecondLevel)}
                                    searchEnabled={true}
                                    className={'form-control dxSelectBorder'}
                                    placeholder={t('menuSecondLevel')}
                                    showClearButton={true}
                                    name="menuSecondLevel"
                                    onValueChanged={handleMenuSecondLevel}
                                />
                                <input type="hidden" />
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("menuThirdLevel")}</Label>
                                <SelectBox
                                    dataSource={auxThirdLevel}
                                    displayExpr="nameSubMenuThirdLevel"
                                    value={auxThirdLevel.find(v => v.IdSubMenuThirdLevel === IdSubMenuThirdLevel)}
                                    searchEnabled={true}
                                    className={'form-control dxSelectBorder'}
                                    placeholder={t('menuThirdLevel')}
                                    showClearButton={true}
                                    name="menuThirdLevel"
                                    onValueChanged={handleMenuThirdLevel}
                                />
                                <input type="hidden" />
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("moduleName")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataModule.moduleName} placeholder={t("moduleName")} {...register('moduleName', { required: true })} />
                                <span>{errors.moduleName && t("errorRequired")}</span>
                            </Col>
                            {isEdit ?
                                <Col md="6 mb-2">
                                    <Label>{t("selectStatus")}</Label>
                                    <SelectBox
                                        dataSource={listStatus}
                                        displayExpr="name"
                                        value={listStatus.find(v => v.id === statusModule)}
                                        searchEnabled={true}
                                        className={'form-control dxSelectBorder'}
                                        placeholder={t('selectStatus')}
                                        showClearButton={true}
                                        name="selectStatus"
                                        onValueChanged={handleStatusModule}
                                    />
                                    <input type="hidden" />
                                    <span>{((statusModule === '' || statusModule === undefined) && validateClass) && t("errorRequired")}</span>
                                </Col>
                                : ''
                            }
                            <Col md="12 mb-1">
                                <Label>{t("description")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataModule.moduleDescription} placeholder={t("description")} {...register('moduleDescription', { required: true })} />
                                <span>{errors.moduleDescription && t("errorRequired")}</span>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={changeStatusModalMenu} >{t('close')}</Button>
                        <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : isEdit ? t('update') : t('create')}</Button>
                    </ModalFooter>
                </Form>

                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                    <span></span></div>
                </div>

            </Modal>
        </Fragment>
    );
}
