import React from 'react';
import '../../home.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import ContentHomePage from '../ContentHomePage';

function HomePage() {
  return (
    <>
      <HeroSection />
      <ContentHomePage/>
      <Footer />
    </>
  );
}

export default HomePage;
