import React, { useState, useEffect } from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import { Container, Row, Col, Form, Input, Label, Button, NavItem, NavLink, Nav, TabContent, TabPane } from 'reactstrap'
import { Password, SignIn, EmailAddress, RememberPassword, ForgotPassword, CreateAccount, FIREBASE, AUTH0, JWT, LoginWithJWT, LoginNormal } from '../constant';
import { useNavigate } from 'react-router';
import { classes } from '../data/layouts';
import axios from "axios"; 
import { useTranslation } from 'react-i18next'; 
import Home from "../home/index";


const HomePage = (props) => {
  const [loading, setLoading] = useState(false)
  const history = useNavigate();
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
  const { t } = useTranslation();

  const [value, setValue] = useState(
    localStorage.getItem('profileURL' || man)
  );
  const [name, setName] = useState(
    localStorage.getItem('Name')
  );

  const [loginAccess, setLogingAccess] = useState(
    localStorage.getItem('loginAccess')
  );

  const [infoUserLogin, setInfoUserLogin] = useState(
    localStorage.getItem('infoUserLogin')
  );

  useEffect(() => {
    localStorage.setItem('profileURL', value);
    localStorage.setItem('Name', name);
    localStorage.setItem("loginAccess", loginAccess);
  }, [value, name, loginAccess]);


  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <Home/>
        </Col>
      </Row>
    </Container>
  );
}

export default HomePage;