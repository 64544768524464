import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { SiWhatsapp, SiFacebook, SiGooglemaps } from "react-icons/si";
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function Footer() {
  return (
    <div className='footer-container'>
      <section className='social-media'>
        <div className='social-media-wrap' align="center">
          <Row>
            <Col md="3 mb-3">
              <div className='footer-logo'>
                <span>
                  <img src={require("../../assets/images/logo/logo1.png")} alt="" className='logoFooter' />
                </span>
              </div>
            </Col>

            <Col md="5 mb-3">
              <small className='website-rights'>®DEVJSON 2022. All Rights Reserved.</small>

            </Col>
            <Col md="4 mb-3">
              <div className='social-icons'>
                <span>
                  <a className='footer-social-icon' href='https://www.facebook.com/Sale-and-repair-of-used-refrigerators-California-107668267771159' target={"blank"}>
                    <SiFacebook></SiFacebook>
                  </a>
                </span>
                <span>
                  <a className='footer-social-icon' href='https://api.whatsapp.com/send?phone=+17473890837&text=Hello! do you have available refrigerators?' target={"blank"}>
                    <SiWhatsapp></SiWhatsapp>
                  </a>
                </span>
                <span >
                  <a className='footer-social-icon' href='https://goo.gl/maps/ThKE6QLSyVt5k6yv7' target={"blank"}>
                    <SiGooglemaps></SiGooglemaps>
                  </a>
                </span>
              </div>
            </Col>
          </Row>


        </div>
      </section>
    </div>
  );
}

export default Footer;
