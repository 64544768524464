import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupDeatilOrder from "./popupDetailOrder";
import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { classes } from '../../../data/layouts';
import { TagBox } from 'devextreme-react/tag-box';

const MyRequestList = () => {
    // To traslate the words
    const { t } = useTranslation();

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    //defaults values 
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');

    // Spaces
    const tab = '\u00A0';

    //array si, no
    const arraySiNo = [{ 'id': 1, 'value': 'Si' }, { 'id': 0, 'value': 'No' }]

    //global vars
    const symbol = "$";
    const [loading, setLoading] = useState(false);

    // To get the information of the categories
    const [listOrders, setListOrders] = useState([]);

    // To get the list of status
    const [listStatus, setListStatus] = useState([]);
    const [status, setStatus] = useState('');

    const [statusSelectedFilter, setStatusSelectedFilter] = useState(['8']);
    const [statusDefault, setStatusDefault] = useState(['8']);


    //modal data
    const [controlModal, setControlModal] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [onlyEditStatus, setOnlyEditStatus]=useState(false);


    // Get the list of status only load once time
    useEffect(() => {


        // We pass like parameter 2 because 2 has the status for orders
        axios.get(`${process.env.REACT_APP_DOMAIN_SERVER}api/processState/${4}`)
            .then((response) => {
                setListStatus(response.data.listStatus);
                getRequestByStatus();

            }).catch((error) => {
                console.log(error);
            });
    }, []);

  

    const cellRenderAction = (data) => {
        return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editOrder(data)} /></div>;
    }

    const editOrder = (e) => {
        if (e.data.status==='10') {
            setOnlyEditStatus(true);
        }else{
            setOnlyEditStatus(false);

        }
        setOrderData(e.data);
        setControlModal(!controlModal);
        setStatus(e.data.status);
    };

    const getRequestByStatus = () => {
        if (infoUserLogin.id !== '' && infoUserLogin.id !== undefined && infoUserLogin.id !== null) {
            let params = {
                idUser: infoUserLogin.id,
                statusSelected: statusSelectedFilter
            };
            params = JSON.stringify(params);
            // Get the list of orders by status and user
            axios.get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getRequestByParam/${params}`)
                .then((response) => {
                    console.log(response.data.myOrders);
                    setListOrders(response.data.myOrders);
                }).catch((error) => {
                    console.log(error);
                });
        }



    }

    const handleStatusSelected = (event) => {
        setStatusSelectedFilter(event.value);
    }

    return (
        <Fragment>
            <Breadcrumb parent={t('requests')} title={t("myRequest")} />
            <Container fluid={true}>
                <Row className="justify-content-md-center">
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="4 mb-3">
                                        <Label>{t("requestStatus")}</Label>
                                        <TagBox
                                            items={listStatus}
                                            defaultValue={statusDefault}
                                            showSelectionControls={true}
                                            className={'form-control dxSelectBorder'}
                                            multiline={false}
                                            displayExpr="name"
                                            valueExpr="id"
                                            selectAllMode="allPages"
                                            onValueChanged={handleStatusSelected}

                                        />
                                    </Col>
                                    <Col md="4 mb-3">
                                        <div className="btn-showcase " style={{ marginTop: '35px' }}>
                                            <Button className="btn-pill" color="primary" onClick={getRequestByStatus}><i className="fa fa-search"></i>{tab + tab}{t('search')}</Button>
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    {/* Popup */}
                                    <PopupDeatilOrder
                                        controlModal={controlModal} setControlModal={setControlModal}
                                        listOrders={listOrders} setListOrders={setListOrders}
                                        orderData={orderData} setOrderData={setOrderData}
                                        listStatus={listStatus} setListStatus={setListStatus}
                                        status={status} setStatus={setStatus}
                                        onlyEditStatus={onlyEditStatus}
                                        statusSelectedFilter={statusSelectedFilter}
                                    />


                                    <Col sm="12" lg="12" xl="12">

                                        <div className="table-responsive">
                                            <div id="data-grid-demo" className="table-primary">
                                                <DataGrid
                                                    dataSource={listOrders}
                                                    keyExpr="idPurchaseOrder"
                                                    showBorders={true}
                                                    rowAlternationEnabled={true}
                                                    columnAutoWidth={true}
                                                    t={t}
                                                >
                                                    <HeaderFilter visible={true} allowSearch={true} />
                                                    <Export enabled={true} />
                                                    <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                                                    <Scrolling
                                                        useNative={false}
                                                        scrollByContent={true}
                                                        scrollByThumb={true}
                                                        showScrollbar="onHover" />
                                                    <Paging defaultPageSize={5} />
                                                    <Pager showPageSizeSelector={true} />
                                                    <Editing
                                                        mode="popup"
                                                        allowUpdating={false}
                                                        allowAdding={false}
                                                        allowDeleting={false}>
                                                    </Editing>
                                                    <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                                                    <Column dataField="idPurchaseOrder" caption={t('orderNumber')} >
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="customerName" caption={t("client")}>
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="phoneNumber" caption={t("phoneNumber")}>
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="productName" caption={t("product")} >
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="notesPurchaseOrder" caption={t('notes')} dataType="text">
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="delivery" caption={t("deliveryService")} >
                                                        <Lookup dataSource={arraySiNo} valueExpr="id" displayExpr="value" />
                                                    </Column>
                                                    <Column dataField="addressDelivery" caption={t('address')} >
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="price" caption={t('price')} dataType="number" format="currency">
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="chargeDelivery" caption={t('chargeDelivery')} dataType="number" format="currency">
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="totalPurchase" caption={t('total')} dataType="number" format="currency">
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="createDate" caption={t('date')} >
                                                        <RequiredRule />
                                                    </Column>
                                                    <Column dataField="status" caption={t("selectStatus")} >
                                                        <Lookup dataSource={listStatus} valueExpr="id" displayExpr="name" />
                                                    </Column>
                                                </DataGrid>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                                    <span></span></div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );

};

export default MyRequestList;
