import React from 'react';
import { Container, Row, Col } from 'reactstrap' 
import Footer from '../Footer';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import { useTranslation } from "react-i18next";
const Services = (props) => {
  const { t } = useTranslation();
  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <Navbar />
         

          <div className='cards' style={{background:"#fff"}}>
          <h1>{t("ourServices")}</h1>

            <div className='cards__container'>
              <div className='cards__wrapper'>
                <ul className='cards__items'>
                  <li className='cards__item'>
                    <Link className='cards__item__link' to='#'>
                      <figure className='cards__item__pic-wrap' data-category={t("deliveryService")}>
                        <img
                          style={{width:'60%', marginLeft: '20%'}}
                          className='cards__item__img'
                          src={`${process.env.PUBLIC_URL}/images/principal_delivery.jpg`}
                        />
                      </figure>
                      <div className='cards__item__info'>
                        <h5 className='cards__item__text' style={{'textAlign':'justify'}}>{t("textDeliveryService")}</h5>
                      </div>
                    </Link>
                  </li>
                  <li className='cards__item'>
                    <Link className='cards__item__link' to='#'>
                      <figure className='cards__item__pic-wrap' data-category={t("repairService")}>
                        <img
                          style={{width:'60%', marginLeft: '20%'}}
                          className='cards__item__img'
                          alt='Travel Image'
                          src={`${process.env.PUBLIC_URL}/images/principal_fixes.jpg`}
                        />
                      </figure>
                      <div className='cards__item__info'>
                        <h5 className='cards__item__text' style={{'textAlign':'justify'}}>
                          {t("textRepairService")}
                        </h5>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <Footer />
        </Col>
      </Row>
    </Container>
  );

}

export default Services;