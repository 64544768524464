import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import { useTranslation } from "react-i18next";
import { FcMenu } from "react-icons/fc";

function Navbar() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  //Set languaje
  const browserLanguaje = navigator.language || navigator.userLanguaje;

  useEffect(() => {
    if (browserLanguaje.includes('es')) {
      i18n.changeLanguage('es');
    } else {
      i18n.changeLanguage('us');
    }

  }, []);
  //end set languaje

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 768) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  const loginMethod = () => {
    navigate(`${process.env.PUBLIC_URL}/login`);
  }

  return (
    <>
      <nav className='navbar'>

        <div className='navbar-container'>

          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <span className='navbar-logo' >
              <img src={require("../../assets/images/logo/logo2.png")} alt="" className='logoHeader' />
              REFRIGERATOR
              <i className='fab fa-typo3' />
            </span>

          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <FcMenu style={{ color: "#FFFFFF", marginTop: '-3px', height: '35px', width: '35px' }}></FcMenu>
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                {t("home")}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to={`${process.env.PUBLIC_URL}/home/components/pages/Services`}
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t("services")}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to={`${process.env.PUBLIC_URL}/home/components/pages/Products`}
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t("products")}
              </Link>
            </li>

            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/login`}
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                {t("singInP")}
              </Link>
            </li>
          </ul>
          {button && <Button buttonStyle='btn--outline' onClick={loginMethod}>{t("singInP")}</Button>}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
