import React from 'react';

import {

  UncontrolledCarousel

} from 'reactstrap';

const items1 = [
  {
    src: "https://res.cloudinary.com/devjson/image/upload/v1674501184/zowis/WhatsApp_Image_2023-01-22_at_1.10.50_PM_1_zmg79c.jpg",
    caption: "",
    altText: "",
  },
  {
    src: "https://res.cloudinary.com/devjson/image/upload/v1674501184/zowis/WhatsApp_Image_2023-01-22_at_1.10.50_PM_1_zmg79c.jpg",
    caption: "",
    altText: "",
  },
  {
    src: "https://res.cloudinary.com/devjson/image/upload/v1674501185/zowis/WhatsApp_Image_2023-01-22_at_1.10.51_PM_1_va7df7.jpg",
    caption: "",
    altText: "",
  },
  {
    src: "https://res.cloudinary.com/devjson/image/upload/v1674501184/zowis/WhatsApp_Image_2023-01-22_at_1.10.51_PM_2_axcu7x.jpg",
    caption: "",
    altText: "",
  }
];

const items2 = [
  {
    src: "https://res.cloudinary.com/devjson/image/upload/v1674501377/zowis/WhatsApp_Image_2023-01-22_at_1.11.33_PM_2_qfwimn.jpg",
    caption: "",
    altText: "",
  },
  {
    src: "https://res.cloudinary.com/devjson/image/upload/v1674501376/zowis/WhatsApp_Image_2023-01-22_at_1.11.34_PM_3_yqmvgd.jpg",
    caption: "",
    altText: "",
  },
  {
    src: "https://res.cloudinary.com/devjson/image/upload/v1674501374/zowis/WhatsApp_Image_2023-01-22_at_1.11.35_PM_stkdxb.jpg",
    caption: "",
    altText: "",
  },
  {
    src: "https://res.cloudinary.com/devjson/image/upload/v1674501377/zowis/WhatsApp_Image_2023-01-22_at_1.11.34_PM_2_azdyq4.jpg",
    caption: "",
    altText: "",
  }
];

export const CarsouselDemoone = () => <UncontrolledCarousel items={items1} />;

export const CarsouselDemotwo = () => <UncontrolledCarousel items={items2} />; 



