import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from "axios";
import { SelectBox } from 'devextreme-react/select-box';

export default function PopupFunctions(
    {
        controlModal, setControlModal,
        dataFunction,
        isEdit,
        listStatus, 
        setListFunctions,
        statusFunction, setStatusFunction,
        listModules,
        IdModule, setIdModule,
}
) {

    const [loading, setLoading] = useState(false);

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    // Class allow validate
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

    const [validateClass, setValidateClass] = useState(false);

    //for errors setup
    const [error, setError] = useState({});

    // User translation
    const { t } = useTranslation();

    const onSubmit: SubmitHandler<FormValues> = data => {
        // If all validations are met we'll call register method
        if (isEdit) {
            updateFunction(data);
        } else {
            createFunction(data);
        }
    }

    useEffect(() => {
        setValidateClass(false);
        reset({
            functionName: dataFunction.functionName,
            functionDescription: dataFunction.functionDescription,
        });
    }, [controlModal])

    const handleStatusFunction = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setStatusFunction(newvalue.value.id);
        } else {
            // Clear the information of Status menu
            setStatusFunction('');
        }
    }

    const handleIdModule = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setIdModule(newvalue.value.IdModule);
        } else {
            // Clear the information of Id menu
            setIdModule('');
        }
    }

    const changeStatusModalMenu = () => {
        setControlModal(!controlModal)
    };

    // Function that allow save a new record
    const createFunction = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
            //validation empy fields
            setLoading(true);

            // Make sure that the vals are not empty
            if (IdModule === '' || IdModule === undefined) {
                setLoading(false);
                return false;
            }

            const infoCreate = {
                functionName: data.functionName,
                functionDescription: data.functionDescription,
                IdModule: IdModule,
                whoCreate: infoUserLogin.id
            };

            axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/functionsCtr`, infoCreate)
                .then((response) => {
                    setValidateClass(false);
                    setLoading(false);
                    toast.info(t('successCreated'));

                    // Load the list of brands
                    loadFunctions();
                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                    console.log(errors);
                });
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }
    };

    // Function that allow update the record
    const updateFunction = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '' && dataFunction.IdModuleFunction !== undefined) {
            //validation empy fields
            if(statusFunction !== undefined && statusFunction !== '') {
                setLoading(true);

                const infoUpdate = {
                    functionName: data.functionName,
                    functionDescription: data.functionDescription,
                    functionStatus: statusFunction,
                    IdModule: IdModule,
                    whodidit: infoUserLogin.id
                };

                axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/functionsCtr/${dataFunction.IdModuleFunction}`, infoUpdate)
                .then((response) => {
                    setValidateClass(false);
                    setLoading(false);
                    toast.info(t('successUpdated'));
                    loadFunctions();
                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                });
            }
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }

    };


    //function to reload the menu
    function loadFunctions() {
        axios
        .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/functionsCtr`)
        .then((response) => {
            setListFunctions(response.data.functions);
            setControlModal(!controlModal);
        })
        .catch((error) => {
            console.log(error);
        });
    }


    return (
        <Fragment>
            <Modal
                size="lg" isOpen={controlModal} centered>
                <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={changeStatusModalMenu}>
                        {isEdit ? t("editInfo") : t("createInfo")}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="6 mb-2">
                                <Label>{t("moduleName")}</Label>
                                <SelectBox
                                    dataSource={listModules}
                                    displayExpr="moduleName"
                                    value={listModules.find(v => v.IdModule === IdModule)}
                                    searchEnabled={true}
                                    className={'form-control dxSelectBorder'}
                                    placeholder={t('moduleName')}
                                    showClearButton={true}
                                    name="moduleName"
                                    onValueChanged={handleIdModule}
                                />
                                <input type="hidden" />
                                <span>{((IdModule === "" || IdModule === undefined) && validateClass) && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("functionName")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataFunction.functionName} placeholder={t("functionName")} {...register('functionName', { required: true })} />
                                <span>{errors.functionName && t("errorRequired")}</span>
                            </Col>
                            {isEdit ?
                                <Col md="6 mb-2">
                                    <Label>{t("selectStatus")}</Label>
                                    <SelectBox
                                        dataSource={listStatus}
                                        displayExpr="name"
                                        value={listStatus.find(v => v.id === statusFunction)}
                                        searchEnabled={true}
                                        className={'form-control dxSelectBorder'}
                                        placeholder={t('selectStatus')}
                                        showClearButton={true}
                                        name="selectStatus"
                                        onValueChanged={handleStatusFunction}
                                    />
                                    <input type="hidden" />
                                    <span>{((statusFunction === '' || statusFunction === undefined) && validateClass) && t("errorRequired")}</span>
                                </Col>
                                : ''
                            }
                            <Col md="12 mb-1">
                                <Label>{t("description")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataFunction.functionDescription} placeholder={t("description")} {...register('functionDescription', { required: true })} />
                                <span>{errors.functionDescription && t("errorRequired")}</span>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={changeStatusModalMenu} >{t('close')}</Button>
                        <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : isEdit ? t('update') : t('create')}</Button>
                    </ModalFooter>
                </Form>

                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                    <span></span></div>
                </div>

            </Modal>
        </Fragment>
    );
}
