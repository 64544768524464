import React from 'react';
import '../../home.css';
import Footer from '../Footer';
import { Col, Container, Row } from 'reactstrap';
import Navbar from '../Navbar';
import ProductInformation from '../../../components/inventory/product/productInformation';


export default function CustomerSeeDetailProduct() {
  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <Navbar />
          <br />
          <ProductInformation />          
          <Footer />
        </Col>
      </Row>
    </Container>

  );
}
