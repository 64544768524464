import React from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, PopoverHeader, PopoverBody, Progress, Input } from 'reactstrap'
import './Cards.css';
import { useTranslation } from "react-i18next";

export default function ContentHomePage() {
  const { t } = useTranslation();
  return(
    <div className='cards' align="center">
      <div align="left">
        <Row>
          <Col sm="14" xl="6">
            <Card className="ribbon-wrapper">
              <CardBody>
                <div className="ribbon ribbon-primary" style={{'marginLeft': '3%'}}><h5>{t("aboutUs")}</h5></div>
                <h5 style={{'textAlign':'justify'}}>{t("textAboutUs")}</h5>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <div align="right">
        <Row>
          <Col sm="14" xl="6">
          </Col>
          <Col sm="14" xl="6">
            <Card className="ribbon-wrapper">
              <CardBody>
                <div className="ribbon ribbon-secondary" style={{'marginLeft': '3%'}}><h5>{t("mission")}</h5></div>
                <h5 style={{'textAlign':'justify'}}>{t("textMission")}</h5>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <div align="left">
        <Row>
          <Col sm="14" xl="6">
            <Card className="ribbon-wrapper">
              <CardBody>
                <div className="ribbon ribbon-success" style={{'marginLeft': '3%'}}><h5>{t("vision")}</h5></div>
                <h5 style={{'textAlign':'justify'}}>{t("textVision")}</h5>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <Col md="6" sm="12">
      </Col>
    </div>
  );
}
