import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from "axios";
import { SelectBox } from 'devextreme-react/select-box';

export default function PopupThirdLevel(
    {
        controlModal, setControlModal,
        dataMenu,
        isEdit,
        listStatus,
        setListMenus,
        statusMenu,
        setStatusMenu,
        listMenuSecondLevel,
        IdSubMenuSecondLevel,
        setIdSubMenuSecondLevel,
    }
) {

    const [loading, setLoading] = useState(false);

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    // Class allow validate
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

    const [validateClass, setValidateClass] = useState(false);

    //for errors setup
    const [error, setError] = useState({});

    // User translation
    const { t } = useTranslation();

    const onSubmit: SubmitHandler<FormValues> = data => {
        // If all validations are met we'll call register method
        if (isEdit) {
            updateMenu(data);
        } else {

            createMenu(data);
        }
    }

    useEffect(() => {
        setValidateClass(false);
        reset({
            nameSubMenuThirdLevel: dataMenu.nameSubMenuThirdLevel,
            pathMenu: dataMenu.pathMenu,
            descriptionSubMenuThirdLevel: dataMenu.descriptionSubMenuThirdLevel,
            orderSubMenuThirdLevel: dataMenu.orderSubMenuThirdLevel,
        });
    }, [controlModal])

    const handleStatusMenu = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setStatusMenu(newvalue.value.id);
        } else {
            // Clear the information of Status menu
            setStatusMenu('');
        }
    }

    const handleMenuSecondLevel = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setIdSubMenuSecondLevel(newvalue.value.IdSubMenuSecondLevel);
        } else {
            // Clear the information of Id menu
            setIdSubMenuSecondLevel('');
        }
    }

    const changeStatusModalMenu = () => {
        setControlModal(!controlModal)
    };

    // Function that allow save a new record
    const createMenu = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
            //validation empy fields
            setLoading(true);

            // Make sure that the vals are not empty
            if (IdSubMenuSecondLevel === '' || IdSubMenuSecondLevel === undefined) {
                setLoading(false);
                return false;
            }

            const infoCreate = {
                nameSubMenuThirdLevel: data.nameSubMenuThirdLevel,
                typeSubMenuThirdLevel: "link",
                descriptionSubMenuThirdLevel: data.descriptionSubMenuThirdLevel,
                pathSubMenuThirdLevel: data.pathMenu,
                IdSubMenuSecondLevel: IdSubMenuSecondLevel,
                orderSubMenuThirdLevel: data.orderSubMenuThirdLevel,
                whoCreated: infoUserLogin.id
            };
            axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/createMenuThirdLevel`, infoCreate)
                .then((response) => {
                    setValidateClass(false);
                    setLoading(false);
                    toast.info(t('successCreated'));

                    // Load the list of brands
                    loadMenu();
                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                    console.log(errors);
                });
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }
    };

    // Function that allow update the record
    const updateMenu = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '' && dataMenu.IdSubMenuThirdLevel !== undefined) {
            //validation empy fields
            if(statusMenu !== undefined && statusMenu !== '') {
                setLoading(true);

                const infoUpdate = {
                    nameSubMenuThirdLevel: data.nameSubMenuThirdLevel,
                    descriptionSubMenuThirdLevel: data.descriptionSubMenuThirdLevel,
                    pathSubMenuThirdLevel: data.pathMenu,
                    orderSubMenuThirdLevel: data.orderSubMenuThirdLevel,
                    statusSubMenuThirdLevel: statusMenu,
                    IdSubMenuSecondLevel: IdSubMenuSecondLevel,
                    whodidit: infoUserLogin.id
                };

                axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/updateMenuThirdLevel/${dataMenu.IdSubMenuThirdLevel}`, infoUpdate)
                .then((response) => {
                    setValidateClass(false);
                    setLoading(false);
                    toast.info(t('successUpdated'));
                    loadMenu();
                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                });
            }
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }

    };


    //function to reload the menu
    function loadMenu() {
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getMenuThirdLevel`)
            .then((response) => {
                setListMenus(response.data.menuThirdLevel);
                setControlModal(!controlModal);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <Fragment>
            <Modal
                size="lg" isOpen={controlModal} centered>
                <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={changeStatusModalMenu}>
                        {isEdit ? t("editInfo") : t("createInfo")}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="6 mb-2">
                                <Label>{t("menuSecondLevel")}</Label>
                                <SelectBox
                                    dataSource={listMenuSecondLevel}
                                    displayExpr="nameSubMenuSecondLevel"
                                    value={listMenuSecondLevel.find(v => v.IdSubMenuSecondLevel === IdSubMenuSecondLevel)}
                                    searchEnabled={true}
                                    className={'form-control dxSelectBorder'}
                                    placeholder={t('menuSecondLevel')}
                                    showClearButton={true}
                                    name="menuSecondLevel"
                                    onValueChanged={handleMenuSecondLevel}
                                />
                                <input type="hidden" />
                                <span>{((IdSubMenuSecondLevel === "" || IdSubMenuSecondLevel === undefined) && validateClass) && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("nameOptionMenu")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataMenu.nameSubMenuThirdLevel} placeholder={t("nameOptionMenu")} {...register('nameSubMenuThirdLevel', { required: true })} />
                                <span>{errors.nameSubMenuThirdLevel && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("orderOptionMenu")}</Label>
                                <input className="form-control btn-pill" type="number" defaultValue={dataMenu.orderSubMenuThirdLevel} placeholder={t("orderOptionMenu")} {...register('orderSubMenuThirdLevel', { required: true })} />
                                <span>{errors.orderSubMenuThirdLevel && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("pathMenu")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataMenu.pathSubMenuThirdLevel} placeholder={t("pathMenu")} {...register('pathMenu', { required: true })} />
                                <span>{errors.pathMenu && t("errorRequired")}</span>
                            </Col>
                            {isEdit ?
                                <Col md="6 mb-2">
                                    <Label>{t("selectStatus")}</Label>
                                    <SelectBox
                                        dataSource={listStatus}
                                        displayExpr="name"
                                        value={listStatus.find(v => v.id === statusMenu)}
                                        searchEnabled={true}
                                        className={'form-control dxSelectBorder'}
                                        placeholder={t('selectStatus')}
                                        showClearButton={true}
                                        name="selectStatus"
                                        onValueChanged={handleStatusMenu}
                                    />
                                    <input type="hidden" />
                                    <span>{((statusMenu === '' || statusMenu === undefined) && validateClass) && t("errorRequired")}</span>
                                </Col>
                                : ''
                            }
                            <Col md="12 mb-1">
                                <Label>{t("description")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataMenu.descriptionSubMenuThirdLevel} placeholder={t("description")} {...register('descriptionSubMenuThirdLevel', { required: true })} />
                                <span>{errors.descriptionSubMenuThirdLevel && t("errorRequired")}</span>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={changeStatusModalMenu} >{t('close')}</Button>
                        <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : isEdit ? t('update') : t('create')}</Button>
                    </ModalFooter>
                </Form>

                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                    <span></span></div>
                </div>

            </Modal>
        </Fragment>
    );
}
