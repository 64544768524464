import React from 'react';
import '../../home.css';
import HeroSection from '../HeroSection';
import SeeProductContainer from '../../../components/inventory/product/seeProductContainer';

import Footer from '../Footer';
import { Col, Container, Row } from 'reactstrap';
import Navbar from '../Navbar';


export default function Products() {
  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <Navbar />
          <br />
          <SeeProductContainer />
          <Footer />
        </Col>
      </Row>
    </Container>

  );
}
