import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from "axios";
import { SelectBox } from 'devextreme-react/select-box';

export default function PopupMenu(
    {
        controlModal, setControlModal,
        dataMenu,
        isEdit,
        listStatus,
        setListMenu,
        statusMenu,
        setStatusMenu,
    }
) {

    const [loading, setLoading] = useState(false);

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    // Class allow validate
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

    const [validateClass, setValidateClass] = useState(false);

    //for errors setup
    const [error, setError] = useState({});

    // User translation
    const { t } = useTranslation();

    const onSubmit: SubmitHandler<FormValues> = data => {
        // If all validations are met we'll call register method
        if (isEdit) {
            updateMenu(data);
        } else {

            createMenu(data);
        }
    }

    useEffect(() => {
        setValidateClass(false);
        reset({
            nameOptionMenu: dataMenu.nameOptionMenu,
            contentMenu: dataMenu.contentMenu,
            descriptionOptionMenu: dataMenu.descriptionOptionMenu,
            orderOptionMenu: dataMenu.orderOptionMenu,
        });
    }, [controlModal])

    const handleStatusMenu = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setStatusMenu(newvalue.value.id);
        } else {
            // Clear the information of Status menu
            setStatusMenu('');
        }
    }

    const changeStatusModalMenu = () => {
        setControlModal(!controlModal)
    };

    // Function that allow save a new record
    const createMenu = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
            //validation empy fields
            setLoading(true);

            const infoCreate = {
                nameOptionMenu: data.nameOptionMenu,
                contentMenu: data.contentMenu,
                descriptionOptionMenu: data.descriptionOptionMenu,
                orderOptionMenu: data.orderOptionMenu,
                whoCreated: infoUserLogin.id
            };
            axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/menuCtr`, infoCreate)
                .then((response) => {
                    setValidateClass(false);
                    setLoading(false);
                    toast.info(t('successCreated'));

                    // Load the list of brands
                    loadMenu();

                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                    console.log(errors);
                });
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }
    };

    // Function that allow update the record
    const updateMenu = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '' && dataMenu.IdMenu !== undefined) {
            //validation empy fields
            if (statusMenu !== undefined && statusMenu !== '') {
                setLoading(true);

                const infoUpdate = {
                    nameOptionMenu: data.nameOptionMenu,
                    contentMenu: data.contentMenu,
                    descriptionOptionMenu: data.descriptionOptionMenu,
                    orderOptionMenu: data.orderOptionMenu,
                    statusOptionMenu: statusMenu,
                    whodidit: infoUserLogin.id
                };
                axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/menuCtr/${dataMenu.IdMenu}`, infoUpdate)
                    .then((response) => {
                        setValidateClass(false);
                        setLoading(false);
                        toast.info(t('successUpdated'));
                        loadMenu();
                    })
                    .catch((errors) => {
                        setError(errors.response.data.messages)
                    });
            }
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }

    };


    //function to reload the menu
    function loadMenu() {
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/menuCtr`)
            .then((response) => {
                setListMenu(response.data.menuFirstLevel);
                setControlModal(!controlModal);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <Fragment>
            <Modal
                size="lg" isOpen={controlModal} centered>
                <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={changeStatusModalMenu}>
                        {isEdit ? t("editInfo") : t("createInfo")}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="6 mb-2">
                                <Label>{t("nameOptionMenu")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataMenu.nameOptionMenu} placeholder={t("nameOptionMenu")} {...register('nameOptionMenu', { required: true })} />
                                <span>{errors.nameOptionMenu && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("contentMenu")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataMenu.contentMenu} placeholder={t("contentMenu")} {...register('contentMenu', { required: true })} />
                                <span>{errors.contentMenu && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("orderOptionMenu")}</Label>
                                <input className="form-control btn-pill" type="number" defaultValue={dataMenu.orderOptionMenu} placeholder={t("orderOptionMenu")} {...register('orderOptionMenu', { required: true })} />
                                <span>{errors.orderOptionMenu && t("errorRequired")}</span>
                            </Col>
                            {isEdit ?
                                <Col md="6 mb-2">
                                    <Label>{t("selectStatus")}</Label>
                                    <SelectBox
                                        dataSource={listStatus}
                                        displayExpr="name"
                                        value={listStatus.find(v => v.id === statusMenu)}
                                        searchEnabled={true}
                                        className={'form-control dxSelectBorder'}
                                        placeholder={t('selectStatus')}
                                        showClearButton={true}
                                        name="selectStatus"
                                        onValueChanged={handleStatusMenu}
                                    />
                                    <input type="hidden" />
                                    <span>{((statusMenu === '' || statusMenu === undefined) && validateClass) && t("errorRequired")}</span>
                                </Col>
                                : ''
                            }
                            <Col md="12 mb-1">
                                <Label>{t("description")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataMenu.descriptionOptionMenu} placeholder={t("description")} {...register('descriptionOptionMenu', { required: true })} />
                                <span>{errors.descriptionOptionMenu && t("errorRequired")}</span>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={changeStatusModalMenu} >{t('close')}</Button>
                        <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : isEdit ? t('update') : t('create')}</Button>
                    </ModalFooter>
                </Form>

                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                    <span></span></div>
                </div>

            </Modal>
        </Fragment>
    );
}
