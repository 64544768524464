import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupModules from "./popupModules";
import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';

const ModulesList = () => {
  // To traslate the words
  const { t } = useTranslation();

  // Spaces
  const tab = '\u00A0';

  // To get the information of the menu
  const [dataModule, setDataModule] = useState([]);

  // To get the information of the menus
  const [listModules, setListModules] = useState([]);

  // To get the information of the menus
  const [listMenuFirstLevel, setListMenuFirstLevel] = useState([]);
  const [listMenuSecondLevel, setListMenuSecondLevel] = useState([]);
  const [listMenuThirdLevel, setListMenuThirdLevel] = useState([]);

  // To get the list of status
  const [listStatus, setListStatus] = useState([]);

  //id de los menus a los que corresponde
  const [IdMenu, setIdMenu] = useState("");
  const [IdSubMenuSecondLevel, setIdSubMenuSecondLevel] = useState("");
  const [IdSubMenuThirdLevel, setIdSubMenuThirdLevel] = useState("");


  // To determinate if the event is create or edit:  edit:true and create:false
  const [isEdit, setIsEdit] = useState(false);

  const [controlModal, setControlModal] = useState(false);

  // To get the status of Provider
  const [statusModule, setStatusModule] = useState('');

    //get list of modules
    useEffect(() => {
      axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/modulesCtr`)
      .then((response) => {
        setListModules(response.data.modules);
      })
      .catch((error) => {
        console.log(error);
      });
    }, []);

  //get list menu primer nivel
  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getMenuFirstLevel`)
    .then((response) => {
      setListMenuFirstLevel(response.data.menuFirstLevel);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  //get list menu segundo nivel
  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getMenuSecondLevel`)
    .then((response) => {
      setListMenuSecondLevel(response.data.menuSecondLevel);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  //get list menu tercer nivel
  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getMenuThirdLevel`)
    .then((response) => {
      setListMenuThirdLevel(response.data.menuThirdLevel);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);


  // Get the list of status only load once time
  useEffect(() => {
    // We pass like parameter 1 because 1 has the general status
    axios
    .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/processState/${1}`)
    .then((response) => {
      setListStatus(response.data.listStatus);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);
  
  const cellRenderAction = (data) => {
    return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editModule(data)} /></div>;
  }

  const createModule = (e) => {
    setDataModule(
      {
        moduleName: "",
        moduleDescription: "",
        statusModule: "",
        IdMenu: "",
        IdSubMenuSecondLevel: "",
        IdSubMenuThirdLevel: "",
      }
    );
    setIsEdit(false);
    setControlModal(!controlModal);
  };

  const editModule = (e) => {
    setIsEdit(true);
    setDataModule(e.data);
    setIdMenu(e.data.IdMenu);
    setIdSubMenuSecondLevel(e.data.IdSubMenuSecondLevel);
    setIdSubMenuThirdLevel(e.data.IdSubMenuThirdLevel);
    setControlModal(!controlModal);
    setStatusModule(e.data.moduleStatus);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Menu" title={t("titleModules")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12">
            <Card>
              <CardBody>
                <Row >
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <div id="data-grid-demo" className="table-primary">

                        {/* Popup */}
                        <PopupModules
                          controlModal={controlModal} setControlModal={setControlModal}
                          dataModule={dataModule}
                          isEdit={isEdit}
                          listStatus={listStatus} 
                          setListModules={setListModules}
                          statusModule={statusModule} setStatusModule={setStatusModule}
                          listMenuFirstLevel={listMenuFirstLevel}
                          listMenuSecondLevel={listMenuSecondLevel}
                          listMenuThirdLevel={listMenuThirdLevel}
                          IdMenu={IdMenu} setIdMenu={setIdMenu}
                          IdSubMenuSecondLevel={IdSubMenuSecondLevel} setIdSubMenuSecondLevel={setIdSubMenuSecondLevel}
                          IdSubMenuThirdLevel={IdSubMenuThirdLevel} setIdSubMenuThirdLevel={setIdSubMenuThirdLevel}
                        />

                        <div className="btn-showcase ">
                          <Button className="btn-pill" color="primary" onClick={createModule}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                        </div>

                        <DataGrid
                          dataSource={listModules}
                          keyExpr="IdModule"
                          showBorders={true}
                          rowAlternationEnabled={true}
                          columnAutoWidth={true}
                          t={t}
                        >
                          <HeaderFilter visible={true} allowSearch={true} />
                          <Export enabled={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                          <Scrolling
                            useNative={false}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar="onHover" />
                          <Paging defaultPageSize={5} />
                          <Pager
                            showPageSizeSelector={true}
                          />

                          <Editing
                            mode="popup"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={false}>
                          </Editing>

                          <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                          <Column dataField="moduleName" caption={t('moduleName')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="IdMenu" caption={t("menuFirstLevel")} >
                            <Lookup dataSource={listMenuFirstLevel} valueExpr="IdMenu" displayExpr="nameOptionMenu" />
                          </Column>
                          <Column dataField="IdSubMenuSecondLevel" caption={t("menuSecondLevel")} >
                            <Lookup dataSource={listMenuSecondLevel} valueExpr="IdSubMenuSecondLevel" displayExpr="nameSubMenuSecondLevel" />
                          </Column>
                          <Column dataField="IdSubMenuThirdLevel" caption={t("menuThirdLevel")} >
                            <Lookup dataSource={listMenuThirdLevel} valueExpr="IdSubMenuThirdLevel" displayExpr="nameSubMenuThirdLevel" />
                          </Column>
                          <Column dataField="moduleDescription" caption={t('description')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="moduleStatus" caption={t("selectStatus")} >
                            <Lookup dataSource={listStatus} valueExpr="id" displayExpr="name" />
                          </Column>
                        </DataGrid>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default ModulesList;
