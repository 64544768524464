import React from 'react';
import '../home.css';
import { Button } from './Button';
import './HeroSection.css';
import ReactPlayer from 'react-player';
import CardItem from './CardItem.jsx';
import authVideo from '../../assets/video/videoHomepage.mp4';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";

export default function HeroSection() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const seeProducts=(e)=>{
    e.preventDefault();
    navigate(`${process.env.PUBLIC_URL}/home/components/pages/Products`);
  }

  return (
    <div className='hero-container'>
      {/* <video loop autoPlay muted>
        <source src={authVideo} type="video/mp4" ></source>
      </video> */}

      <br />
      <h1>HDZ REFRIGERATORS {process.env.PUBLIC_URL}</h1>
      <p>{t("lookingFor")}</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={seeProducts}
        >
          {t("seeProducts")} <i className='fa fa-eye' />
        </Button>
      </div>
    </div>
  );
}

