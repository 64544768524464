import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Alert, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Media, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from "axios";
import { SelectBox } from 'devextreme-react/select-box';
import AlertItemWithDarkDismissing from '../../alert_system/setUpAlert';




export default function PopupImageManage(
    {
        controlOpenModalManageManyImg,
        setControlOpenModalManageManyImg,
        selectedRowKeys,
        listStatus,
        productId,
        setDataImagesProduct
    }
) {

    const [loading, setLoading] = useState(false);

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    // Class allow validate
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

    const [validateClass, setValidateClass] = useState(false);

    // User translation
    const { t } = useTranslation();

    const [visibleCustomer, setVisibleCustomer] = useState(false);




    // Define error array
    const [error, setError] = useState(
        {
            'visibleImg': '',
        }
    );




    const onSubmit: SubmitHandler<FormValues> = data => {
        // If all validations are met we'll call register method
        updateImage(data);
    }


    useEffect(() => {
        setValidateClass(false)
        setVisibleCustomer(false);

    }, [controlOpenModalManageManyImg])


    const handleVisibleCustomer = (newvalue) => {
        if (newvalue.value !== null) {
            // Set the id
            if (newvalue.value !== undefined) {
                setVisibleCustomer(newvalue.value.id);
            }

        } else {
            // Clear the information of Status 
            setVisibleCustomer('');
        }
    }

    // Function that allow update the record
    const updateImage = (data) => {

        if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
            if (visibleCustomer !== undefined && visibleCustomer !== '' && visibleCustomer !== false && productId !== '' && productId !== false && selectedRowKeys.length>0) {
                
                console.log(selectedRowKeys);
                setLoading(true);

                const infoUpdate = {
                    visibleCustomer: parseInt(visibleCustomer),
                    whodidit: infoUserLogin.id 
                };

                // Convert to int
                for (let index = 0; index < selectedRowKeys.length; index++) {
                    
                    selectedRowKeys[index]=parseInt(selectedRowKeys[index]);
                    
                }

                let formData = new FormData();

                formData.append('infoUpdate', JSON.stringify(infoUpdate));
                formData.append('idImagesSelected', JSON.stringify(selectedRowKeys));

                formData.append('id', productId);

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/uptManyStatusImage`, formData, config)
                    .then((response) => {
                        setValidateClass(false);
                        setLoading(false);

                        console.log(response);
                        toast.info(t('successUpdated'));
                        loadProductImages();

                    })
                    .catch((errors) => {
                        setError(errors.response.data.messages)
                        console.log(errors);
                    });
            }

        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }

    };


    function loadProductImages() {
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/imagesById/${productId}`)
            .then((response) => {
                setDataImagesProduct(response.data.imagesByProduct);
                setControlOpenModalManageManyImg(!controlOpenModalManageManyImg);

            })
            .catch((error) => {
                console.log(error);
            });
    }


    const changeStatusModal = () => {
        setControlOpenModalManageManyImg(!controlOpenModalManageManyImg)
    };


    const handleChangeStatusVisible = (newvalue) => {

        if (newvalue.value !== null && newvalue.value !== '') {
            // Set the id
            if (newvalue.value !== undefined) {
                setVisibleCustomer(newvalue.value.id);
            }

        } else {
            // Clear the information 
            setVisibleCustomer('');

        }
    }


    return (
        <Fragment>
            <Modal
                size="lg" isOpen={controlOpenModalManageManyImg} centered>
                <Form id='formEditImage' className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={changeStatusModal}>
                        {t("editInfo")}
                    </ModalHeader>
                    <ModalBody>
                        {
                            <Container fluid={true}>
                                <Row>
                                    <Col md="12 mb-1">
                                        {[
                                            {
                                                id: 7,
                                                alertFirstWord:t("Warning"),
                                                alerttxt: t("warningImg"),
                                                alertcolor: 'light dark',
                                                // btnclose: 'default'
                                            },
                                        ].map((item, i) => {
                                            return <AlertItemWithDarkDismissing key={i} item={item} />;
                                        })}
                                    </Col>

                                    <Col md="6 mb-2">
                                        <Label>{t("visibleCustomer")}</Label>
                                        <SelectBox
                                            dataSource={listStatus}
                                            value={listStatus.find(v => v.id === visibleCustomer)}
                                            displayExpr="name"
                                            searchEnabled={true}
                                            className={'form-control dxSelectBorder'}
                                            placeholder={t('visibleCustomer')}
                                            showClearButton={true}
                                            name="selectBrand"
                                            onValueChanged={handleChangeStatusVisible}
                                        />
                                        <input type="hidden" />
                                        <span>{((visibleCustomer === '' || visibleCustomer === undefined) && validateClass) && t("errorSubCategory")}</span>
                                    </Col>
                                </Row>
                            </Container >
                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={changeStatusModal} >{t('close')}</Button>
                        <Button color="primary" type="submit" onClick={() => setValidateClass(true)} >{t('update')}</Button>
                    </ModalFooter>
                </Form>
                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                    <span></span></div>
                </div>


            </Modal>
        </Fragment>
    );
}
