import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupFunctions from "./popupFunctions";
import DataGrid, { Column, Editing, Popup, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';

const FunctionsList = () => {
  // To traslate the words
  const { t } = useTranslation();

  // Spaces
  const tab = '\u00A0';

  // To get the information of the funtion
  const [dataFunction, setDataFunction] = useState([]);

  // To get the information of the functios
  const [listFunctions, setListFunctions] = useState([]);

  // To get the information of the menus
  const [listModules, setListModules] = useState([]);

  // To get the list of status
  const [listStatus, setListStatus] = useState([]);

  //id de los modulos a los que corresponde
  const [IdModule, setIdModule] = useState("");

  // To determinate if the event is create or edit:  edit:true and create:false
  const [isEdit, setIsEdit] = useState(false);

  const [controlModal, setControlModal] = useState(false);

  // To get the status of Provider
  const [statusFunction, setStatusFunction] = useState('');

  //get list of functions
  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/functionsCtr`)
    .then((response) => {
      setListFunctions(response.data.functions);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  //get list modulos
  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/modulesCtr`)
    .then((response) => {
      setListModules(response.data.modules);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  // Get the list of status only load once time
  useEffect(() => {
    // We pass like parameter 1 because 1 has the general status
    axios
    .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/processState/${1}`)
    .then((response) => {
      setListStatus(response.data.listStatus);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);
  
  const cellRenderAction = (data) => {
    return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editFunction(data)} /></div>;
  }

  const createFunction = (e) => {
    setDataFunction(
      {
        functionName: "",
        functionDescription: "",
        statusFunction: "",
        IdModule: "",
        IdSubMenuSecondLevel: "",
        IdSubMenuThirdLevel: "",
      }
    );
    setIsEdit(false);
    setControlModal(!controlModal);
  };

  const editFunction = (e) => {
    setIsEdit(true);
    setDataFunction(e.data);
    setIdModule(e.data.IdModule);
    setControlModal(!controlModal);
    setStatusFunction(e.data.functionStatus);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Menu" title={t("titleFunctions")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12">
            <Card>
              <CardBody>
                <Row >
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <div id="data-grid-demo" className="table-primary">

                        {/* Popup */}
                        <PopupFunctions
                          controlModal={controlModal} setControlModal={setControlModal}
                          dataFunction={dataFunction}
                          isEdit={isEdit}
                          listStatus={listStatus} 
                          setListFunctions={setListFunctions}
                          statusFunction={statusFunction} setStatusFunction={setStatusFunction}
                          listModules={listModules}
                          IdModule={IdModule} setIdModule={setIdModule}
                        />

                        <div className="btn-showcase ">
                          <Button className="btn-pill" color="primary" onClick={createFunction}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                        </div>

                        <DataGrid
                          dataSource={listFunctions}
                          keyExpr="IdModuleFunction"
                          showBorders={true}
                          rowAlternationEnabled={true}
                          columnAutoWidth={true}
                          t={t}
                        >
                          <HeaderFilter visible={true} allowSearch={true} />
                          <Export enabled={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                          <Scrolling
                            useNative={false}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar="onHover" />
                          <Paging defaultPageSize={5} />
                          <Pager
                            showPageSizeSelector={true}
                          />

                          <Editing
                            mode="popup"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={false}>
                          </Editing>

                          <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                          <Column dataField="functionName" caption={t('functionName')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="IdModule" caption={t("moduleName")} >
                            <Lookup dataSource={listModules} valueExpr="IdModule" displayExpr="moduleName" />
                          </Column>
                          <Column dataField="functionDescription" caption={t('description')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="functionStatus" caption={t("selectStatus")} >
                            <Lookup dataSource={listStatus} valueExpr="id" displayExpr="name" />
                          </Column>
                        </DataGrid>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default FunctionsList;
