import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Productpage from "../../application/ecommerce-app/productpage";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";


const ProductInformation = (props) => {

  // Get the information of the logged user
  const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));
  // To traslate the words
  const { t } = useTranslation();

  let location = useLocation();

  const [singleProductImg, setSingleProductImg] = useState({});
  // Take id
  let { id } = useParams();

  // Save language
  const [languageClient, setLanguageClient] = useState('en');

  //Get the language
  const browserLanguaje = navigator.language || navigator.userLanguaje;

  const [permissionsModule, setPermissionsModule] = useState([]);


  useEffect(() => {

    if (browserLanguaje.includes('es')) {
      setLanguageClient('es');
    } else {
      setLanguageClient('us');
    }
    loadProductsById(id);
  }, [id]);

  useEffect(() => {
    // Get general permissions

    if (infoUserLogin !== null) {
      if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
        axios
          .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getPermission/${infoUserLogin.idRol}`)
          .then((result) => {
            setPermissionsModule(result.data.response);
          })
          .catch((error) => {
            console.log(error);
          });

      } else {
        setPermissionsModule([]);
      }
    } else {
      setPermissionsModule([]);

    }

  }, []);

  function loadProductsById(idProduct) {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getInfoProductoById/${idProduct}`)
      .then((response) => {
        setSingleProductImg(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const copyLink = () => {
    // let url = "http://localhost:3000" + location.pathname;
    // Online
    let url="https://hdz-refrigerators.devsoftjson.com"+location.pathname;

    navigator.clipboard.writeText(url);
    toast.info(t('copySuccessfully'));

  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12" xl="10">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="12" lg="6" xl="6" className="col-12">

                    <div className="product-box row" align="center">
                      <Productpage data={singleProductImg} />
                    </div>
                  </Col>
                  <Col sm="12" lg="6" xl="6">

                    <Col className="col-12" style={{ marginTop: '10px' }}>
                      <h6 className="sub-title formTitleRegisterCustomer">{t("information")}</h6>
                    </Col>
                    <Row>
                      <Col md="12 mb-1">
                        <h4>{singleProductImg.name}</h4>
                      </Col>
                      <Col md="12 mb-1">
                        <div className="product-price">
                          {"$"}{singleProductImg.price}
                        </div>
                      </Col>
                      <Col md="12 mb-1">
                        <h6 className="f-w-600 descriptionInfo">{t("productDetail")}</h6>
                        <p className="descriptionInfo"><strong>{t("nameBrand")} :</strong>{singleProductImg.tags}</p>
                        <p className="mb-0 descriptionInfo">{singleProductImg.description}</p>
                      </Col>
                    </Row>

                    <ModalFooter>
                      {
                        permissionsModule.find(v => { return v.functionName === 'copiar_enlace_producto' }) ?
                          (
                            <Button color="primary" type="button" onClick={() => copyLink()} >{t('copyLink')}</Button>

                          ) :
                          ''
                      }

                    </ModalFooter>


                    <br /><br />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment >
  );

};

export default ProductInformation;
