import React, { Fragment } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'
import { CarsouselDemoone, CarsouselDemotwo } from './carouselComponent'

const CarouselsWelcome = () => {
    return (
        <Container fluid={true}>
            <Row>
                <Col sm="12" md="10" xl="6">
                    <CardBody>
                        <CarsouselDemoone />
                    </CardBody>
                </Col>

                <Col sm="12" md="10" xl="6">
                    <CardBody>
                        <CarsouselDemotwo />
                    </CardBody>
                </Col>
            </Row>
        </Container>


    );
}

export default CarouselsWelcome;