import React, { Fragment, useState, useEffect, useRef } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Container, Row, Form, Col, Card, CardHeader, Table, Label, CardBody, CardFooter, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupPermission from "./popupPermission";
import DataGrid, { Column, Editing, Popup, Paging, Lookup, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';
import ArrayStore from 'devextreme/data/array_store';
import { SelectBox } from 'devextreme-react/select-box';
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify';
import List from 'devextreme-react/list';


const PermissionList = () => {
  // To traslate the words
  const { t } = useTranslation();

  // Get the information of the logged user
  const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

  // To get the information of the module function
  const [listModuleFunction, setListModuleFunction] = useState([]);
  const [currentModuleMenu, setCurrentModuleMenu] = useState([]);
  const [listAttrs, setListAttrs] = useState({ class: 'list' });
  const [selectedItemKeysFunction, setSelectedItemKeysFunction] = useState([]);

  const [selectedItemKeys, setSelectedItemKeys] = useState([]);
  const arrayPermissions = useRef([]);

  const [dataRol, setDataRol] = useState([]);
  const [idRolSelected, setIdRolSelected] = useState('');
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [validateClass, setValidateClass] = useState(false);
  const [loading, setLoading] = useState(false);
  const arrayCurrentlyFun = useRef([]);


  const [dataSourceOptions, setDataSourceOptions] = useState([])

  const [listFunctions, setListFunctions] = useState([]);

  const [dataSourceOptionsFunction, setDataSourceOptionsFunction] = useState([]);

  // Get the list of status only load once time
  useEffect(() => {
    // Get modules function
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getModuleListWithMenu`)
      .then((response) => {
        setListModuleFunction(response.data.moduleMenu);
        setCurrentModuleMenu(response.data.moduleMenu[0]);
        setSelectedItemKeys([response.data.moduleMenu[0].IdModule])
        setDataSourceOptions(
          {
            store: new ArrayStore({
              data: response.data.moduleMenu,
              key: 'IdModule',
            }),
            group: 'nameOptionMenu',
            searchExpr: ['moduleName', 'moduleDescription', 'nameOptionMenu'],
          }
        );

      })
      .catch((error) => {
        console.log(error);
      });

    // Get the list of roles 
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/roles`)
      .then((response) => {
        setDataRol(response.data.roles);
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);

  const renderListItem = (item) => {
    return (
      <div>
        <div className="hotel">
          <div className="address">{`${item.moduleName}`}</div>
        </div>
      </div>
    );
  }

  const renderListGroup = (group) => {
    return <div className="city">{group.key}</div>;
  }


  const handleListSelectionChange = (ev) => {

    // Information selected
    setCurrentModuleMenu(ev.addedItems[0]);
    setSelectedItemKeys([ev.addedItems[0].IdModule]);

    // Looking for the list of function by Id module
    fillFunctionList(ev.addedItems[0].IdModule);

  }

  function fillFunctionList(idModule) {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getFunctionsByModule/${idModule}`)
      .then((response) => {

        // Set up the array to Function list
        let dataSourceFunction = new ArrayStore({
          key: 'IdModuleFunction',
          data: response.data.functionModule,
        });

        setDataSourceOptionsFunction(dataSourceFunction);

        // Get the function list previously selected
        let foundKey = '';
        foundKey = arrayPermissions.current.filter(info => {
          return response.data.functionModule.find(v => v.IdModuleFunction === info.IdModuleFunction);
        });

        setSelectedItemKeysFunction(foundKey.map(v => { return v.IdModuleFunction }));

      })
      .catch((error) => {
        console.log(error);
      });
  }




  // Function that allows to know when the list of functions changes, that is, when an element is selected or deselected.
  const handleListSelectionChangeFunction = (ev) => {

    if (ev.addedItems.length > 0) {
      // If another permission was selected, it is added to the general list.
      ev.addedItems.map(v => {
        arrayPermissions.current.push(v);
        arrayCurrentlyFun.current.push(v);
      });
    } else if (ev.removedItems.length > 0) {
      // When an item is romoved, it is removed from the list
      ev.removedItems.map(v => {
        arrayPermissions.current = arrayPermissions.current.filter(info => {
          return info.IdModuleFunction !== v.IdModuleFunction;
        });
        arrayCurrentlyFun.current = arrayCurrentlyFun.current.filter(info => {
          return info.IdModuleFunction !== v.IdModuleFunction;
        });
      });

    }

    // Get the arrays id of the permission selected
    setSelectedItemKeysFunction(arrayCurrentlyFun.current.map(v => { return v.IdModuleFunction }));

  }

  // Functions
  const renderListItemFunction = (item) => {
    return (
      <div>
        <div className="hotel" align="left">
          <div className="nameFunction">{item.functionName}</div>
          <div>{`${item.functionDescription}`}</div>
        </div>
      </div>
    );
  }


  // Function that allow to know when a rol changed value
  const handleChangeRol = (newvalue) => {
    if (newvalue.value !== null) {

      // Set the id
      if (newvalue.value !== undefined) {
        loadPermissionRol(newvalue.value.id);
        setIdRolSelected(newvalue.value.id);
      } else {
        setIdRolSelected('');
      }

    } else {
      // Clear the information 
      setIdRolSelected('');

    }
  }

  const loadPermissionRol = (idRolChange) => {

    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getPermissionsByRol/${idRolChange}`)
      .then((response) => {

        // We place the permissions that the role has in the reference to select the default permissions
        arrayPermissions.current = response.data.permissionsList;

        // Find the permissions by Id Module previously selected
        let idFunctionSe = response.data.permissionsList.filter(v => {
          return v.IdModule === selectedItemKeys[0];
        }).map(data => data.IdModuleFunction);

        setSelectedItemKeysFunction(idFunctionSe);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //Evento que sucede al dar clic al botón de crear
  const onSubmit: SubmitHandler<FormValues> = (data) => {
    // If all validations are met we'll call register method
    if (idRolSelected !== '' && idRolSelected !== null && idRolSelected !== undefined) {
      if (arrayPermissions.current.length > 0) {
        saveInformation();
      } else {
        setTimeout(() => {
          toast.error(t('cantPermission'));
        }, 200);
      }
    }
  }

  // Function that allow save a new record
  function saveInformation() {

    if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
      setLoading(true);
      console.log(arrayPermissions.current);
      console.log(idRolSelected);

      const infoCreate = {
        arrayPermissions: JSON.stringify(arrayPermissions.current),
        IdRolPermit: idRolSelected,
        whoCreated: infoUserLogin.id
      };

      console.log(infoCreate);
      axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/createPermission`, infoCreate)
        .then((response) => {
          setValidateClass(false);
          setLoading(false);
          toast.info(t('successCreated'));
          console.log(response);

        })
        .catch((errors) => {
          console.log(errors);
        });
    } else {
      setTimeout(() => {
        toast.error(t('errorLogin'));
      }, 200);
    }
  }


  return (
    <Fragment>
      <Breadcrumb parent="Menu" title={t("titlePermissionList")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12">
            <Card>

              <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <CardBody>
                  <Row >
                    <Col md="3 mb-2" align="right">
                      <Label className="nameFunction" style={{ fontSize: '20px' }}>{t("rol")}:</Label>
                    </Col>
                    <Col md="9 mb-2">
                      <SelectBox
                        dataSource={dataRol}
                        displayExpr="rol"
                        onValueChanged={handleChangeRol}
                        searchEnabled={true}
                        className={'form-control dxSelectBorder'}
                        placeholder={t('rol')}
                        showClearButton={true}
                        name="selectRol"
                      />
                      <input type="hidden" />
                      <span>{((idRolSelected === '' || idRolSelected === undefined) && validateClass) && t("errorRol")}</span>
                    </Col>
                    <Col md="3 mb-2">
                      <div className="leftPermissionModule" align="center">
                        <List
                          selectionMode="single"
                          dataSource={dataSourceOptions}
                          grouped={true}
                          searchEnabled={true}
                          selectedItemKeys={selectedItemKeys}
                          onSelectionChanged={handleListSelectionChange}
                          itemRender={renderListItem}
                          groupRender={renderListGroup}
                          elementAttr={listAttrs}
                        />
                      </div>
                    </Col>
                    <Col md="9 mb-2" >
                      <Col md="12 mb-1" align="center">
                        <div className="titleModule">{currentModuleMenu.moduleName}</div>
                      </Col>
                      <Col md="12 mb-1" className="descriptionBackground">
                        <div className="descriptText">{currentModuleMenu.moduleDescription}</div>
                      </Col>

                      <Col md="12 mb-1" align="center">
                        <div className="leftPermission" align="center">

                          <List
                            dataSource={dataSourceOptionsFunction}
                            searchEnabled={true}
                            selectedItemKeys={selectedItemKeysFunction}
                            itemRender={renderListItemFunction}
                            showSelectionControls={true}
                            selectionMode='all'
                            selectAllMode='page'
                            searchExpr={['functionName', 'functionDescription']}
                            onSelectionChanged={handleListSelectionChangeFunction}
                          />
                        </div>
                      </Col>

                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button className="me-1" color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)}>{loading ? t("processing") : t("btnAssing")}</Button>
                </CardFooter>
              </Form>

              <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                <span></span></div>
              </div>
            </Card>

          </Col>
        </Row>
      </Container>
    </Fragment >
  );

};

export default PermissionList;
