import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from "axios";
import { SelectBox } from 'devextreme-react/select-box';

export default function PopupSecondLevel(
    {
        controlModal, setControlModal,
        dataMenu,
        isEdit,
        listStatus,
        setListMenus,
        statusMenu,
        setStatusMenu,
        listMenuFirstLevel,
        IdMenu,
        setIdMenu,
    }
) {

    const [loading, setLoading] = useState(false);

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    // Class allow validate
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

    const [validateClass, setValidateClass] = useState(false);

    //for errors setup
    const [error, setError] = useState({});

    // User translation
    const { t } = useTranslation();

    const onSubmit: SubmitHandler<FormValues> = data => {
        // If all validations are met we'll call register method
        if (isEdit) {
            updateMenu(data);
        } else {

            createMenu(data);
        }
    }

    useEffect(() => {
        setValidateClass(false);
        reset({
            nameSubMenuSecondLevel: dataMenu.nameSubMenuSecondLevel,
            iconSubMenuSecondLevel: dataMenu.iconSubMenuSecondLevel,
            descriptionSubMenuSecondLevel: dataMenu.descriptionSubMenuSecondLevel,
            orderSubMenuSecondLevel: dataMenu.orderSubMenuSecondLevel,
        });
    }, [controlModal])

    const handleStatusMenu = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setStatusMenu(newvalue.value.id);
        } else {
            // Clear the information of Status menu
            setStatusMenu('');
        }
    }

    const handleMenuFirstLevel = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setIdMenu(newvalue.value.IdMenu);
        } else {
            // Clear the information of Id menu
            setIdMenu('');
        }
    }

    const changeStatusModalMenu = () => {
        setControlModal(!controlModal)
    };

    // Function that allow save a new record
    const createMenu = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
            //validation empy fields
            setLoading(true);

            // Make sure that the provider and brand are not empty
            if (IdMenu === '' || IdMenu === undefined) {
                setLoading(false);
                return false;
            }

            const infoCreate = {
                nameSubMenuSecondLevel: data.nameSubMenuSecondLevel,
                typeSubMenuSecondLevel: "sub",
                descriptionSubMenuSecondLevel: data.descriptionSubMenuSecondLevel,
                iconSubMenuSecondLevel: data.iconSubMenuSecondLevel,
                IdMenu: IdMenu,
                orderSubMenuSecondLevel: data.orderSubMenuSecondLevel,
                whoCreated: infoUserLogin.id
            };
            axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/createMenuSecondLevel`, infoCreate)
                .then((response) => {
                    setValidateClass(false);
                    setLoading(false);
                    toast.info(t('successCreated'));

                    // Load the list of brands
                    loadMenu();

                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                    console.log(errors);
                });
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }
    };

    // Function that allow update the record
    const updateMenu = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '' && dataMenu.IdSubMenuSecondLevel !== undefined) {
            //validation empy fields
            if(statusMenu !== undefined && statusMenu !== '') {
                setLoading(true);

                const infoUpdate = {
                    nameSubMenuSecondLevel: data.nameSubMenuSecondLevel,
                    descriptionSubMenuSecondLevel: data.descriptionSubMenuSecondLevel,
                    iconSubMenuSecondLevel: data.iconSubMenuSecondLevel,
                    orderSubMenuSecondLevel: data.orderSubMenuSecondLevel,
                    statusSubMenuSecondLevel: statusMenu,
                    IdMenu: IdMenu,
                    whodidit: infoUserLogin.id
                };

                axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/updateMenuSecondLevel/${dataMenu.IdSubMenuSecondLevel}`, infoUpdate)
                .then((response) => {
                    setValidateClass(false);
                    setLoading(false);
                    toast.info(t('successUpdated'));
                    loadMenu();
                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                });
            }
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }

    };


    //function to reload the menu
    function loadMenu() {
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getMenuSecondLevel`)
            .then((response) => {
                setListMenus(response.data.menuSecondLevel);
                setControlModal(!controlModal);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <Fragment>
            <Modal
                size="lg" isOpen={controlModal} centered>
                <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={changeStatusModalMenu}>
                        {isEdit ? t("editInfo") : t("createInfo")}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="6 mb-2">
                                <Label>{t("menuFirstLevel")}</Label>
                                <SelectBox
                                    dataSource={listMenuFirstLevel}
                                    displayExpr="nameOptionMenu"
                                    value={listMenuFirstLevel.find(v => v.IdMenu === IdMenu)}
                                    searchEnabled={true}
                                    className={'form-control dxSelectBorder'}
                                    placeholder={t('menuFirstLevel')}
                                    showClearButton={true}
                                    name="menuFirstLevel"
                                    onValueChanged={handleMenuFirstLevel}
                                />
                                <input type="hidden" />
                                <span>{((IdMenu === "" || IdMenu === undefined) && validateClass) && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("nameOptionMenu")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataMenu.nameSubMenuSecondLevel} placeholder={t("nameOptionMenu")} {...register('nameSubMenuSecondLevel', { required: true })} />
                                <span>{errors.nameSubMenuSecondLevel && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("orderOptionMenu")}</Label>
                                <input className="form-control btn-pill" type="number" defaultValue={dataMenu.orderSubMenuSecondLevel} placeholder={t("orderOptionMenu")} {...register('orderSubMenuSecondLevel', { required: true })} />
                                <span>{errors.orderSubMenuSecondLevel && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("iconName")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataMenu.iconSubMenuSecondLevel} placeholder={t("iconName")} {...register('iconSubMenuSecondLevel', { required: true })} />
                                <span>{errors.iconSubMenuSecondLevel && t("errorRequired")}</span>
                            </Col>
                            {isEdit ?
                                <Col md="6 mb-2">
                                    <Label>{t("selectStatus")}</Label>
                                    <SelectBox
                                        dataSource={listStatus}
                                        displayExpr="name"
                                        value={listStatus.find(v => v.id === statusMenu)}
                                        searchEnabled={true}
                                        className={'form-control dxSelectBorder'}
                                        placeholder={t('selectStatus')}
                                        showClearButton={true}
                                        name="selectStatus"
                                        onValueChanged={handleStatusMenu}
                                    />
                                    <input type="hidden" />
                                    <span>{((statusMenu === '' || statusMenu === undefined) && validateClass) && t("errorRequired")}</span>
                                </Col>
                                : ''
                            }
                            <Col md="12 mb-1">
                                <Label>{t("description")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataMenu.descriptionSubMenuSecondLevel} placeholder={t("description")} {...register('descriptionSubMenuSecondLevel', { required: true })} />
                                <span>{errors.descriptionSubMenuSecondLevel && t("errorRequired")}</span>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={changeStatusModalMenu} >{t('close')}</Button>
                        <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : isEdit ? t('update') : t('create')}</Button>
                    </ModalFooter>
                </Form>

                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                    <span></span></div>
                </div>

            </Modal>
        </Fragment>
    );
}
