export const getCartTotal = cartItems => {
    var total = 0;
    var items = 0;
    for (var i = 0; i < cartItems.length; i++) {
        items = cartItems[i].qty * cartItems[i].price
        total = total + items;
    }
    return total;
}

export const getBrands = (products) => {

    var uniqueBrands = [];
    if (products.length > 0) {
        products.forEach((product, index) => {
            if (product.tags) {
                product.tags.forEach((tag) => {
                    if (uniqueBrands.indexOf(tag) === -1) {
                        uniqueBrands.push(tag);
                    }
                })
            }
        })
    }

    return uniqueBrands;
}


export const getColors = (products) => {
    var uniqueColors = [];
    products.forEach((product, index) => {
        if (product.colors) {
            product.colors.forEach((color) => {
                if (uniqueColors.indexOf(color) === -1) {
                    uniqueColors.push(color);
                }
            })
        }
    })
    return uniqueColors;
}

export const getMinMaxPrice = (products) => {

    let min = 1, max = 2000;

    products.forEach((product, index) => {
        let v = product.price;
        min = (v < min) ? v : min;
        max = (v > max) ? v : max;
    })

    return { 'min': min, 'max': max };
}

export const getVisibleproducts = (data, { brand, color, value, sortBy, searchBy }) => {
    if (data.length>0) {
    let newData = data.filter(product => {

        let brandMatch;
        if (product.tags)
            brandMatch = product.tags.some(tag => brand.includes(tag))
        else
            brandMatch = true;

        const priceBetween = (product.price >= value.min && product.price <= value.max)
        var searchByName = true;

        if (searchBy !== "") {
             searchByName = (product.name.toLowerCase().indexOf(searchBy) > -1)
        } 

        return (brandMatch === true  && searchByName === true && priceBetween === true);
    }).sort((product1, product2) => {

        if (sortBy === 'HighestPrices') {
            return product2.price < product1.price ? -1 : 1;
        }
        else if (sortBy === 'LowestPrices') {
            return product2.price > product1.price ? -1 : 1;
        }
        else {
            return product2.price !== product1.price ? 1 : 1;
        }
    });
    return newData;
    }else{
        return [];
    }


}