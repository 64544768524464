import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import 'devextreme/dist/css/dx.material.teal.light.css';
import PopupCustomer from "./popupCustomer";
import DataGrid, { Column, Editing, Popup, Toolbar, Item, Paging, Lookup, Form, SearchPanel, Scrolling, Pager, Export, HeaderFilter, RequiredRule } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { useTranslation } from 'react-i18next';

const CustomersList = () => {
  // To traslate the words
  const { t } = useTranslation();

  // Spaces
  const tab = '\u00A0';

  // To get the information of the sub categories
  const [dataCustomer, setDataCustomer] = useState([]);

  // To get the information of the categories
  const [listCustomers, setListCustomers] = useState([]);

  // To get the list of status
  const [listStatus, setListStatus] = useState([]);

  // To determinate if the event is create or edit:  edit:true and create:false
  const [isEdit, setIsEdit] = useState(false);

  const [controlModal, setControlModal] = useState(false);

  // To get the status of Customer
  const [statusCustomer, setStatusCustomer] = useState('');

  // To get the status of Customer
  const [comment, setComment] = useState('');

  // Use effect is launch one time when the page load
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/clients`)
      .then((response) => {
        setListCustomers(response.data.clients);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Get the list of status only load once time
  useEffect(() => {
    // We pass like parameter 1 because 1 has the general status
    axios
      .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/processState/${1}`)
      .then((response) => {
        setListStatus(response.data.listStatus);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const cellRenderAction = (data) => {
    return <div align="center"><i style={{ cursor: 'pointer' }} className="icofont icofont-ui-edit" onClick={() => editCustomer(data)} /></div>;
  }

  // See link meta
  const seeLinkMetaF = (data) => {
    return <div align="center"><a href={data.data.linkMeta} target="blank" ><i style={{ cursor: 'pointer' }} className="icon-link" /> </a></div>;
  }

  const createProvider = (e) => {
    setDataCustomer(
      {
        commercialName: "",
        giro: "",
        document: "",
        address: "",
        email: "",
        phoneNumber: "",
        status: "",
        comment: ""
      }
    );
    setIsEdit(false);
    setControlModal(!controlModal);
  };

  const editCustomer = (e) => {
    setIsEdit(true);
    setDataCustomer(e.data);
    setControlModal(!controlModal);
    setStatusCustomer(e.data.status);
    setComment(e.data.comment);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Clients" title={t("clients")} />
      <Container fluid={true}>
        <Row className="justify-content-md-center">
          <Col sm="12">
            <Card>
              <CardBody>
                <Row >
                  <Col sm="12" lg="12" xl="12">
                    <div className="table-responsive">
                      <div id="data-grid-demo" className="table-primary">

                        {/* Popup */}
                        <PopupCustomer
                          controlModal={controlModal} setControlModal={setControlModal}
                          dataCustomer={dataCustomer}
                          isEdit={isEdit}
                          listStatus={listStatus}
                          setListCustomers={setListCustomers}
                          statusCustomer={statusCustomer}
                          setStatusCustomer={setStatusCustomer}
                          comment={comment}
                          setComment={setComment}
                        />

                        <div className="btn-showcase ">
                          <Button className="btn-pill" color="primary" onClick={createProvider}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                        </div>

                        <DataGrid
                          dataSource={listCustomers}
                          keyExpr="id"
                          showBorders={true}
                          rowAlternationEnabled={true}
                          columnAutoWidth={true}
                          t={t}
                        >
                          <HeaderFilter visible={true} allowSearch={true} />
                          <Export enabled={true} />
                          <SearchPanel visible={true} highlightCaseSensitive={true} width={350} />
                          <Scrolling
                            useNative={false}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar="onHover" />
                          <Paging defaultPageSize={5} />
                          <Pager
                            showPageSizeSelector={true}
                          />

                          <Editing
                            mode="popup"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={false}>
                          </Editing>

                          <Column caption={t('actions')} cellRender={cellRenderAction} width={100} />
                          <Column dataField="linkMeta" caption={t('linkMeta')} cellRender={seeLinkMetaF} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="completeNameClient" caption={t('Cliente')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="address" caption={t('address')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="document" caption={t('document')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="email" caption={t('email')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="phoneNumber" caption={t('phoneNumber')} >
                            <RequiredRule />
                          </Column>

                          <Column dataField="comment" caption={t('notes')} >
                            <RequiredRule />
                          </Column>
                          <Column dataField="status" caption={t("selectStatus")} >
                            <Lookup dataSource={listStatus} valueExpr="id" displayExpr="name" />
                          </Column>
                          {/* <Toolbar>
                            <Item location="before">
                              <div className="btn-showcase">
                                <Button className="btn-pill" color="primary" onClick={createProvider}><i className="icofont icofont-ui-add"></i>{tab + tab}{t('create')}</Button>
                              </div>
                            </Item>
                            <Item
                              name="exportButton"
                              locateInMenu="auto"
                              location="after"
                            />
                            <Item name="searchPanel" />
                          </Toolbar> */}

                        </DataGrid>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default CustomersList;
