import React, { useState, Fragment, useEffect } from 'react';
import { Container, Row, Col, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from "reactstrap";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { SelectBox } from 'devextreme-react/select-box';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';


export default function PopupDetailOrder({
    controlModal, setControlModal,
    orderData, setDataOrder,
    listStatus, setListStatus,
    status, setStatus,
    listOrders, setListOrders,
    onlyEditStatus,
    statusSelectedFilter
}) {
    //vars
    const symbol = "$";

    // User translation
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    //for errors setup
    const [error, setError] = useState({});

    const [validateClass, setValidateClass] = useState(false);

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    const changeStatusModalDetail = () => {
        setControlModal(!controlModal)
    };

    // Class allow validate
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

    const handleStatusOrder = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setStatus(newvalue.value.id);
        } else {
            // Clear the information of Status brand
            setStatus('');
        }
    }

    const onSubmit: SubmitHandler<FormValues> = data => {
        updateOrder(data);
    }
    console.log(status);

    // Function that allow update the record
    const updateOrder = (data) => {

        if (infoUserLogin.id !== null && infoUserLogin.id !== '' && orderData.idPurchaseOrder !== undefined) {
            //validation empy fields
            if (status !== undefined && status !== '') {
                setLoading(true);

                const infoUpdate = {
                    status: status,
                    whodidit: infoUserLogin.id
                };

                axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/purchaseOrderCtr/${orderData.idPurchaseOrder}`, infoUpdate)
                    .then((response) => {
                        setValidateClass(false);
                        setLoading(false);

                        toast.info(t('successUpdated'));
                        loadOrders();

                    })
                    .catch((errors) => {
                        setError(errors.response.data.messages);
                    });
            }
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }

    }

    function loadOrders() {
        if (infoUserLogin.id !== '' && infoUserLogin.id !== undefined && infoUserLogin.id !== null) {
            let params = {
                idUser: infoUserLogin.id,
                statusSelected: statusSelectedFilter
            };
            params = JSON.stringify(params);
            // Get the list of orders by status and user
            axios.get(`${process.env.REACT_APP_DOMAIN_SERVER}api/getRequestByParam/${params}`)
                .then((response) => {

                    setListOrders(response.data.myOrders);
                    setControlModal(!controlModal)
                }).catch((error) => {
                    console.log(error);
                });
        }

    }
    // function loadOrders() {
    //     // Get the list of orders
    //     axios.get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/getAllPurchaseOrder`)
    //         .then((response) => {
    //             setListOrders(response.data.orders);
    //             setControlModal(!controlModal)

    //         }).catch((error) => {
    //             console.log(error);
    //         });
    // }


    return (
        <Fragment>
            <Modal size="lg" isOpen={controlModal}>
                <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader className="text-center">
                        <b>{t("detailPurchaseTitle")} #{orderData.idPurchaseOrder}</b>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col sm="12" lg="12" xl="12">
                                <div className="table-responsive">
                                    <div id="data-grid-demo" className="table-primary">
                                        <Table className="table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2" className="text-center"><b>{t('clientData')}</b></td>
                                                </tr>
                                                <tr>
                                                    <td><b>{t('nameFirst')}</b></td>
                                                    <td>{orderData.customerName}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>{t('email')}</b></td>
                                                    <td>{orderData.email}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>{t('phoneNumber')}</b></td>
                                                    <td>{orderData.phoneNumber}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>{t('address')}</b></td>
                                                    <td>{orderData.addressDelivery}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="text-center"><b>{t('orderData')}</b></td>
                                                </tr>
                                                <tr>
                                                    <td><b>{t('product')}</b></td>
                                                    <td>{orderData.productName}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>{t('deliveryService')}</b></td>
                                                    <td>{symbol + orderData.chargeDelivery}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>{t('deliveryDate')}</b></td>
                                                    <td>{
                                                        (orderData.deliveryDate != '' && orderData.deliveryDate != undefined) ?
                                                            moment(orderData.deliveryDate).format('DD/MM/YYYY') : 'N/A'
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><b>{t('price')}</b></td>
                                                    <td>{symbol + orderData.price}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>{t('total')}</b></td>
                                                    <td>{symbol + orderData.totalPurchase}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <br></br>
                                        <Col md="6 mb-2">
                                            <Label>{t("selectStatus")}</Label>
                                            {
                                                status !== "" ?
                                                    (
                                                        <SelectBox
                                                            dataSource={listStatus}
                                                            displayExpr="name"
                                                            value={listStatus.find(v => v.id === status)}
                                                            searchEnabled={true}
                                                            className={'form-control dxSelectBorder'}
                                                            placeholder={t('selectStatus')}
                                                            showClearButton={true}
                                                            name="selectStatus"
                                                            onValueChanged={handleStatusOrder}
                                                            readOnly={onlyEditStatus}
                                                        />
                                                    ) :
                                                    ''
                                            }

                                            <input type="hidden" />
                                            <span>{((status === '' || status === undefined) && validateClass) && t("errorRequired")}</span>
                                        </Col>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={onlyEditStatus} onClick={() => setValidateClass(true)} >{loading ? t("processing") : t('update')}</Button>
                        <Button color="secondary" onClick={changeStatusModalDetail} >{t('close')}</Button>
                    </ModalFooter>
                </Form>
                <div className={loading ? 'loader-wrapper back' : 'loader-wrapper back loderhide'}><div className="loader-index">
                    <span></span></div>
                </div>
            </Modal>
        </Fragment>
    );
}
