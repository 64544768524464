import React, { useState, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { Container, Row, Col, Form, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from "axios";
import { SelectBox } from 'devextreme-react/select-box';

export default function PopupCustomer(
    {
        controlModal, setControlModal,
        dataCustomer,
        isEdit,
        listStatus,
        setListCustomers,
        statusCustomer,
        setStatusCustomer,
        comment,
        setComment
    }
) {

    const [loading, setLoading] = useState(false);

    // Get the information of the logged user
    const infoUserLogin = JSON.parse(localStorage.getItem('infoUserLogin'));

    // Class allow validate
    const { register, reset, handleSubmit, formState: { errors }, control } = useForm();

    const [validateClass, setValidateClass] = useState(false);

    //for errors setup
    const [error, setError] = useState({});

    // User translation
    const { t } = useTranslation();

    const onSubmit: SubmitHandler<FormValues> = data => {
        // If all validations are met we'll call register method
        if (isEdit) {
            updateCustomer(data);
        } else {
            createCustomer(data);
        }
    }

    useEffect(() => {
        setValidateClass(false);
        reset({
            firstName: dataCustomer.firstName,
            lastName: dataCustomer.lastName,
            document: dataCustomer.document,
            address: dataCustomer.address,
            email: dataCustomer.email,
            phoneNumber: dataCustomer.phoneNumber,
        });
    }, [controlModal])

    const handleStatusCustomer = (newvalue) => {
        if (newvalue.value !== null && newvalue.value !== undefined) {
            // Set the id
            setStatusCustomer(newvalue.value.id);
        } else {
            // Clear the information of status
            setStatusCustomer('');
        }
    }

    const changeStatusModalCustomer = () => {
        setControlModal(!controlModal)
    };

    // Function that allow save a new record
    const createCustomer = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '') {
            //validation empy fields
            setLoading(true);

            const infoCreate = {
                firstName: data.firstName,
                lastName: data.lastName,
                document: data.document,
                address: data.address,
                email: data.email,
                phoneNumber: data.phoneNumber,
                whoCreated: infoUserLogin.id,
                linkMeta: data.linkMeta,
                comment: comment
            };
            axios.post(`${process.env.REACT_APP_DOMAIN_SERVER}api/clients`, infoCreate)
                .then((response) => {
                    setValidateClass(false);
                    setLoading(false);
                    toast.info(t('successCreated'));

                    // Load the list of brands
                    loadCustomers();

                })
                .catch((errors) => {
                    setError(errors.response.data.messages)
                    console.log(errors);
                });
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }
    };

    // Function that allow update the record
    const updateCustomer = (data) => {
        if (infoUserLogin.id !== null && infoUserLogin.id !== '' && dataCustomer.id !== undefined) {
            //validation empy fields
            if (statusCustomer !== undefined && statusCustomer !== '') {
                setLoading(true);

                const infoUpdate = {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    document: data.document,
                    address: data.address,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    status: statusCustomer,
                    whodidit: infoUserLogin.id,
                    linkMeta: data.linkMeta,
                    comment: comment
                };
                axios.put(`${process.env.REACT_APP_DOMAIN_SERVER}api/clients/${dataCustomer.id}`, infoUpdate)
                    .then((response) => {
                        setValidateClass(false);
                        setLoading(false);

                        toast.info(t('successUpdated'));
                        loadCustomers();

                    })
                    .catch((errors) => {
                        setError(errors.response.data.messages)
                        console.log(errors);
                    });
            }
        } else {
            setTimeout(() => {
                toast.error(t('errorLogin'));
            }, 200);
        }

    };


    //function to reload the subcategories
    function loadCustomers() {
        axios
            .get(`${process.env.REACT_APP_DOMAIN_SERVER}/api/clients`)
            .then((response) => {
                setListCustomers(response.data.clients);
                setControlModal(!controlModal);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <Fragment>
            <Modal
                size="lg" isOpen={controlModal} centered>
                <Form className={`needs-validation tooltip-validation ${validateClass ? 'validateClass' : ''}`} noValidate="" onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={changeStatusModalCustomer}>
                        {isEdit ? t("editInfo") : t("createInfo")}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="6 mb-2">
                                <Label>{t("firstName")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.firstName} placeholder={t("placeHolderFirstName")} {...register('firstName', { required: true })} />
                                <span>{errors.firstName && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("lastName")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.lastName} placeholder={t("placeHolderLastName")} {...register('lastName', { required: true })} />
                                <span>{errors.lastName && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("document")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.documnet} placeholder={t("placeHolderDocument")} {...register('document', { required: false })} />
                                <span>{errors.document && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("email")}</Label>
                                <input className="form-control btn-pill" type="text" placeholder={t("placeholderEmail")} defaultValue={dataCustomer.email} {...register('email', {
                                    required: false,
                                    pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
                                })} />
                                <span>{errors.email && t("errorEmail")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("phoneNumber")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.phoneNumber} placeholder={t("placeHolderPhoneNumber")} {...register('phoneNumber', { required: true })} />
                                <span>{errors.phoneNumber && t("errorRequired")}</span>
                            </Col>
                            <Col md="6 mb-2">
                                <Label>{t("linkMeta")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.linkMeta} placeholder={t("placeHolderLinkMeta")} {...register('linkMeta', { required: false })} />
                                {/* <span>{errors.linkMeta && t("errorRequired")}</span> */}
                            </Col>

                            {isEdit ?
                                <Col md="6 mb-2">
                                    <Label>{t("selectStatus")}</Label>
                                    <SelectBox
                                        dataSource={listStatus}
                                        displayExpr="name"
                                        value={listStatus.find(v => v.id === statusCustomer)}
                                        searchEnabled={true}
                                        className={'form-control dxSelectBorder'}
                                        placeholder={t('selectStatus')}
                                        showClearButton={true}
                                        name="selectStatus"
                                        onValueChanged={handleStatusCustomer}
                                    />
                                    <input type="hidden" />
                                    <span>{((statusCustomer === '' || statusCustomer === undefined) && validateClass) && t("errorRequired")}</span>
                                </Col>
                                : ''
                            }
                            <Col md="12 mb-1">
                                <Label>{t("address")}</Label>
                                <input className="form-control btn-pill" type="text" defaultValue={dataCustomer.address} placeholder={t("placeHolderAddress")} {...register('address', { required: false })} />
                            </Col>

                            <Col md="12 mb-1">
                                <Label>{t("notes")}</Label>
                                <Input type="textarea" className="form-control btn-pill" rows="2" name="comment" placeholder={t("notes")} defaultValue={dataCustomer.comment} onChange={(e) => setComment(e.target.value)} />
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={changeStatusModalCustomer} >{t('close')}</Button>
                        <Button color="primary" type="submit" disabled={loading ? loading : loading} onClick={() => setValidateClass(true)} >{loading ? t("processing") : isEdit ? t('update') : t('create')}</Button>
                    </ModalFooter>
                </Form>

            </Modal>
        </Fragment>
    );
}
