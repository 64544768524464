import React, { Fragment, useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, Button, Media, CardBody } from 'reactstrap'
import Slider from 'react-slick';
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { addToCart, watchfetchSingleProducts } from '../../../redux/ecommerce/product/action';
import { classes } from '../../../data/layouts';
import zIndex from '@material-ui/core/styles/zIndex';



const Productpage = (props) => {
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const [state, setState] = useState({ nav1: null, nav2: null });
    const slider1 = useRef();
    const slider2 = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(watchfetchSingleProducts())
        setState({
            nav1: slider1.current,
            nav2: slider2.current
        });
    }, [dispatch]);

    const { nav1, nav2 } = state;
    // const singleItem = useSelector(content => content.data.singleItem)
    const symbol = useSelector(content => content.data.symbol)

    // Get the array of the product selected
    const singleItem = props.data;
    var cont = 0;
    return (
        <Fragment>
            {/* <Container fluid={true}> */}
            <div className="product-page-main p-0">
                <Row>
                    <Col xl="12 xl-100 box-col-8">

                        <Slider
                            asNavFor={nav2}
                            arrows={false}
                            infinite={true}
                            ref={slider => (slider1.current = slider)} className="product-slider widthSlickSlider">
                            {
                                singleItem.variants ? Object.keys(singleItem.variants).map((key, index) => {
                                    cont++;

                                    return (
                                        <div className="item" key={index}>
                                            <Media src={singleItem.variants[key].link} alt="" className="img-fluid" />
                                        </div>
                                    )
                                }) :
                                    <Media className="img-fluid widthSlider" src={singleItem.img} alt="" />
                            }
                        </Slider>



                        {
                            cont > 0 ? (
                                <Slider asNavFor={nav1}
                                    ref={slider => (slider2.current = slider)}
                                    slidesToShow={cont}
                                    arrows={false}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    infinite={true}
                                    speed={500}
                                    className="small-slick widthSlickSlider"
                                    adaptiveHeight={true}
                                >
                                    {singleItem.variants ?

                                        Object.keys(singleItem.variants).map((key, index) => {
                                            return (
                                                <div className="item" key={index} >
                                                    <Media src={singleItem.variants[key].link} alt="" className="img-fluid" />
                                                </div>
                                            )
                                        })
                                        : ''}

                                </Slider>
                            ) : ''
                        }


                    </Col>
                </Row>
            </div>
            {/* </Container> */}
        </Fragment>
    );
}
export default Productpage

