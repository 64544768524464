import { Container, Row, Col, Form, Alert, Label, Input, Card, FormGroup, InputGroup, InputGroupText, CardHeader, Table, CardBody, Media, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useState, Fragment, useEffect } from 'react';


export default function AlertItemWithDarkDismissing  (props) {
    const {item} = props;
    const [Open, setOpen] = useState(true)
    const Toggle = () => setOpen(!Open);
    
    return (
        <Alert className="alert-dismissible" color={item.alertcolor}
        isOpen={Open}
        target={"Alert-" + item.id}
        ><strong>{item.alertFirstWord} </strong> {item.alerttxt}
        {/* <Button className="btn-close btnCloseWarning" color={item.btnclose} id={"Alert-" + item.id} onClick={Toggle} ></Button> */}
        </Alert>
    );
  };