import React, { useState, useEffect, Fragment, useRef } from 'react';
import Breadcrumb from '../../../layout/breadcrumb'
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, ListGroup, Form, Input, Media, Modal, ModalHeader, ModalBody, InputGroup, InputGroupText } from 'reactstrap'
import { SEARCH_BY, SORT_BY, ADD_TO_CART, ADD_TO_WISHLIST } from '../../../redux/actionTypes'
import { Grid, List } from 'react-feather'
import { Link, useNavigate } from 'react-router-dom'
import errorImg from '../../../assets/images/search-not-found.png';
import Allfilters from '../../../components/application/ecommerce-app/filters/allfilters'
import { filterBrand, filterColor, filterPrice, filterSearch } from '../../../redux/ecommerce/filter/action'

import { getVisibleproducts } from './product.service'
import { watchfetchProducts } from "../../../redux/ecommerce/product/action"
import { Filters, ShowingProducts, Featured, LowestPrices, HighestPrices, NotFoundData, ProductDetails, Quantity, AddToCart, ViewDetails, ProductSizeArray, Ideas } from "../../../constant";
import { classes } from '../../../data/layouts';
import { useTranslation } from 'react-i18next';
import Productpage from '../../application/ecommerce-app/productpage';
import SeeProductContainer from './seeProductContainer';


const SeeProduct = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  return (
    <Fragment>
      <Breadcrumb parent="Product" title={t("product")} />
      <SeeProductContainer></SeeProductContainer>
    </Fragment>
  );
}

export default SeeProduct;